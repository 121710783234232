import React from 'react';

import { StyledCounterLabel } from './styles';

interface CounterLabelProps {
  value: string;
}

const CounterLabel: React.FC<CounterLabelProps> = ({ value }) => {
  return <StyledCounterLabel>{value}</StyledCounterLabel>;
};

export default CounterLabel;
