import React from 'react';

import { StyledHeader } from './styles';

interface HeaderProps {
  leftSideContent?: React.ReactNode;
  rightSideContent?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
}

const Header: React.FC<HeaderProps> = ({
  leftSideContent,
  rightSideContent,
  children,
  className = '',
}) => {
  return (
    <StyledHeader className={`header ${className}`}>
      <div className="left-side">{leftSideContent}</div>
      <div className="center">{children}</div>
      <div className="right-side">{rightSideContent}</div>
    </StyledHeader>
  );
};

export default Header;
