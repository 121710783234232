import React from 'react';

import Loading from 'components/molecule/Loading';

import { StyledPageLoading } from './styles';

const PageLoading: React.FC = () => {
  return (
    <StyledPageLoading className="page-loading">
      <Loading type="bubbles" />
    </StyledPageLoading>
  );
};

export default PageLoading;
