import styled from 'styled-components';

export const StyledPageLoading = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: var(--z-index-level-8);

  background: linear-gradient(
    30deg,
    rgba(255, 255, 255, 0.2),
    rgba(220, 254, 217, 0.2),
    rgba(210, 209, 255, 0.2),
    rgba(255, 248, 188, 0.2)
  );
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);

  display: flex;
  justify-content: center;
  align-items: center;

  .loading {
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
    -o-filter: blur(0px);
    -ms-filter: blur(0px);
    filter: blur(0px);
  }
`;
