import { createSlice } from '@reduxjs/toolkit';

import ThemeState from './types';

const initialState: ThemeState = {
  primary_color_100: '#D2D1FF',
  primary_color_200: '#4746D8',
  primary_color_300: '#363AA3',
  primary_color_400: '#1C2359',
  secondary_color_100: '#FFAB2C',
  secondary_color_200: '#FF7A00',
  tertiary_color_1: '#6DDC47',
  tertiary_color_2: '#FF5348',
  tertiary_color_3: '#EF6B8A',
  support_pastel_color_1: '#FAF3EA',
  support_pastel_color_2: '#8F52CC',
  title: 'USEFLOW Tech',
};

export const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setTheme: (state, action) => {
      state.primary_color_100 = action.payload.primary_color_100;
      state.primary_color_200 = action.payload.primary_color_200;
      state.primary_color_300 = action.payload.primary_color_300;
      state.primary_color_400 = action.payload.primary_color_400;
      state.secondary_color_100 = action.payload.secondary_color_100;
      state.secondary_color_200 = action.payload.secondary_color_200;
      state.tertiary_color_1 = action.payload.tertiary_color_1;
      state.tertiary_color_2 = action.payload.tertiary_color_2;
      state.tertiary_color_3 = action.payload.tertiary_color_3;
      state.support_pastel_color_1 = action.payload.support_pastel_color_1;
      state.support_pastel_color_2 = action.payload.support_pastel_color_2;
      state.title = action.payload.title;
    },
    resetTheme: (state) => {
      const {
        primary_color_100,
        primary_color_200,
        primary_color_300,
        primary_color_400,
        secondary_color_100,
        secondary_color_200,
        tertiary_color_1,
        tertiary_color_2,
        tertiary_color_3,
        support_pastel_color_1,
        support_pastel_color_2,
        title,
      } = initialState;

      state.primary_color_100 = primary_color_100;
      state.primary_color_200 = primary_color_200;
      state.primary_color_300 = primary_color_300;
      state.primary_color_400 = primary_color_400;
      state.secondary_color_100 = secondary_color_100;
      state.secondary_color_200 = secondary_color_200;
      state.tertiary_color_1 = tertiary_color_1;
      state.tertiary_color_2 = tertiary_color_2;
      state.tertiary_color_3 = tertiary_color_3;
      state.support_pastel_color_1 = support_pastel_color_1;
      state.support_pastel_color_2 = support_pastel_color_2;
      state.title = title;
    },
  },
});

export const { setTheme, resetTheme } = themeSlice.actions;

export default themeSlice.reducer;
