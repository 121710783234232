import React from 'react';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';

import MainLayout from 'layouts/MainLayout';

import ErrorLayout from 'layouts/PublicLayouts/ErrorLayout';

import Error403 from 'pages/general/Error403';
import Error404 from 'pages/general/Error404';
import Error500 from 'pages/general/Error500';
import Form from 'pages/general/Form';

import FormLayout from 'layouts/PublicLayouts/FormLayout';
import FormStats from 'pages/general/FormStats';
import Dashboard from 'pages/general/Dashboard';
import FormsDashboardLayout from 'layouts/RestrictedLayouts/FormsDashboardLayout';
import DashboardClient from 'pages/general/DashboardClient';
import AuthLayout from 'layouts/PublicLayouts/AuthLayout';
import Login from 'pages/general/Login';
import LoginCallback from 'pages/general/LoginCallback';

const AppRoutes: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          element={
            <MainLayout>
              <Outlet />
            </MainLayout>
          }
        >
          {/* Public routes */}

          <Route
            element={
              <AuthLayout>
                <Outlet />
              </AuthLayout>
            }
          >
            <Route path="/login" element={<Login />} />
          </Route>

          <Route path="/sso/signin" element={<LoginCallback />} />

          <Route
            element={
              <FormLayout>
                <Outlet />
              </FormLayout>
            }
          >
            <Route path="/:token" element={<Form />} />
            <Route path="/:token/stats" element={<FormStats />} />
          </Route>

          {/* Private routes */}
          <Route
            element={
              <FormsDashboardLayout>
                <Outlet />
              </FormsDashboardLayout>
            }
          >
            <Route path="/staff" element={<Dashboard />} />
            <Route path="/staff/:formId" element={<DashboardClient />} />
          </Route>

          <Route
            element={
              <ErrorLayout>
                <Outlet />
              </ErrorLayout>
            }
          >
            <Route path="*" element={<Error404 />} />
            <Route path="/error-403" element={<Error403 />} />
            <Route path="/error-500" element={<Error500 />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
