import React from 'react';
import { useTranslation } from 'react-i18next';

import { GridContainer, GridRow, GridColumn } from 'components/atom/Grid';
import Text from 'components/atom/Text';
import Button from 'components/molecule/Button';
import Image from 'components/molecule/Image';

import pageNotFoundGif from 'assets/images/pagenotfound.gif';

import { StyledError403 } from './styles';

const Error403: React.FC = () => {
  const { t } = useTranslation();

  return (
    <StyledError403 className="error-403-page">
      <GridContainer>
        <GridRow>
          <GridColumn desktop="6" tablet="6" mobile="12">
            <div className="message-wrapper">
              <Text as="h1">{t('403')}</Text>
              <Text as="h2">
                {t('Content is not available for this user profile')}
              </Text>
              <Text as="h5">
                {t(
                  'Sorry, some areas of our system can only be accessed with proper account permissions.',
                )}
              </Text>
              <div className="button-wrapper">
                <Button to="/">{t('Back to home')}</Button>
              </div>
            </div>
          </GridColumn>
          <GridColumn desktop="6" tablet="6" mobile="12">
            <div className="image-wrapper">
              <Image src={pageNotFoundGif} />
            </div>
          </GridColumn>
        </GridRow>
      </GridContainer>
    </StyledError403>
  );
};

export default Error403;
