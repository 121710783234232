import styled from 'styled-components';

const getColumnWidth = (value: string) => {
  const currentValue = parseInt(value, 10);
  if (!currentValue) return false;

  const width = currentValue > 1 ? (currentValue / 12) * 100 : 100;
  return `width: ${width}%;`;
};

interface GridColumnProps {
  mobile: string;
  tablet: string;
  desktop: string;
}

export const GridColumn = styled.div.attrs(() => ({
  className: 'grid-column',
}))<GridColumnProps>`
  @media only screen and (max-width: 720px) {
    ${({ mobile }) => mobile && getColumnWidth(mobile)}
  }
  @media only screen and (min-width: 720px) {
    ${({ tablet }) => tablet && getColumnWidth(tablet)}
  }
  @media only screen and (min-width: 960px) {
    ${({ desktop }) => desktop && getColumnWidth(desktop)}
  }
`;

export default GridColumn;
