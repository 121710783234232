import styled from 'styled-components';

interface StyledModalProps {
  width: string;
}

export const StyledModal = styled.div<StyledModalProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: var(--z-index-level-4);
  padding-top: 74px;
  overflow-y: scroll;

  background: linear-gradient(
    30deg,
    rgba(255, 255, 255, 0.2),
    rgba(69, 201, 58, 0.2),
    rgba(71, 70, 216, 0.2),
    rgba(255, 231, 15, 0.2)
  );

  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);

  display: flex;
  justify-content: center;
  align-items: flex-start;

  .modal-content-wrapper {
    width: max-content;
    position: relative;
    width: ${({ width }) => (width ? width : '100%')};
    max-width: ${({ width }) => (width ? width : '600px')};
    margin-bottom: 74px;

    opacity: 0;
    animation: fadeIn 0.5s;
    -webkit-animation: fadeIn 0.5s;
    -moz-animation: fadeIn 0.5s;
    -o-animation: fadeIn 0.5s;
    -ms-animation: fadeIn 0.5s;
    animation-fill-mode: forwards;

    .card {
      width: ${({ width }) => (width ? width : '100%')};
      padding: 32px;
    }

    .button.close-modal-button {
      position: absolute;
      right: -52px;
      background: var(--white-color);

      .icon {
        font-size: 22px;
      }
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
