import { createSlice } from '@reduxjs/toolkit';

import UserState from './types';

const initialState: UserState = {
  avatar: null,
  id: null,
  name: '',
  slug: '',
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      const { avatar, id, name, slug } = action.payload;

      state.avatar = avatar;
      state.id = id;
      state.name = name;
      state.slug = slug;
    },
    clearUser: (state) => {
      const { avatar, id, name, slug } = initialState;

      state.avatar = avatar;
      state.id = id;
      state.name = name;
      state.slug = slug;
    },
  },
});

export const { setUser, clearUser } = userSlice.actions;

export default userSlice.reducer;
