import styled from 'styled-components';

export const StyledErrorLayout = styled.div.attrs(() => ({
  className: 'error-layout',
}))`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  .header {
    .back-logo {
      display: flex;

      .button {
        margin-right: 12px;
      }
    }
  }
`;

export default StyledErrorLayout;
