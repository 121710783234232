import { createSlice } from '@reduxjs/toolkit';

import AppLoadingState from './types';

const initialState: AppLoadingState = {
  show: true,
};

export const AppLoadingSlice = createSlice({
  name: 'appLoading',
  initialState,
  reducers: {
    setAppLoading: (state, action) => {
      state.show = action.payload;
    },
  },
});

export const { setAppLoading } = AppLoadingSlice.actions;

export default AppLoadingSlice.reducer;
