import React from 'react';

import Icon from 'components/atom/Icon';
import Tag from 'components/atom/Tag';

import useflowLogo from 'assets/images/useflow.svg';

import { StyledLogo } from './styles';

const AvailableSizeTypes = {
  normal: 'normal',
  large: 'large',
};

interface LogoProps {
  size?: keyof typeof AvailableSizeTypes;
}

const Logo: React.FC<LogoProps> = ({ size = 'normal' }) => {
  return (
    <StyledLogo className={`logo ${size}`}>
      <Tag theme="primary" radius="8px">
        <Icon name="logo" color="white-color" />
      </Tag>
      <img src={useflowLogo} alt="USEFLOW" />
    </StyledLogo>
  );
};

export default Logo;
