import styled from 'styled-components';

export const StyledAppLoading = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: var(--dark-color);
  z-index: var(--z-index-level-6);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .loading {
    margin-top: 32px;
  }

  .image {
    position: absolute;
    top: calc(50% - 100px);
  }

  .image.app-logo {
    opacity: 1;
  }

  .image.app-logo-inverse {
    opacity: 0;
  }

  &.show-animation {
    .image.app-logo {
      opacity: 0;
      animation: fadeIn 2s;
      -webkit-animation: fadeIn 2s;
      -moz-animation: fadeIn 2s;
      -o-animation: fadeIn 2s;
      -ms-animation: fadeIn 2s;
      animation-delay: 1000ms;
      animation-fill-mode: forwards;
    }

    .image.app-logo-inverse {
      opacity: 1;
      animation: fadeOut 2s;
      -webkit-animation: fadeOut 2s;
      -moz-animation: fadeOut 2s;
      -o-animation: fadeOut 2s;
      -ms-animation: fadeOut 2s;
      animation-delay: 1000ms;
      animation-fill-mode: forwards;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @-moz-keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @-webkit-keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @-o-keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @-ms-keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;
