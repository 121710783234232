import { css } from 'styled-components';

const SelectThemes = css`
  .select.dark {
    .react-select {
      .react-select__control {
        border-color: var(--dark-color);
        background: var(--dark-color);

        .react-select__single-value {
          color: var(--white-color);
        }

        .react-select__input {
          color: var(--white-color) !important;
        }

        &.react-select__control--is-focused {
          background: var(--grayscale-400);
        }

        .react-select__indicator {
          .icon {
            color: var(--white-color);
          }
        }
      }

      .react-select__menu {
        background: var(--dark-color);
        .react-select__menu-list {
          .react-select__option {
            color: var(--white-color);

            &.react-select__option--is-focused {
              background: var(--grayscale-400);
              color: var(--white-color);
            }

            &.react-select__option--is-selected {
              background: var(--grayscale-300);
              color: var(--white-color);
            }
          }

          .react-select__menu-notice {
            color: var(--white-color);
          }
        }
      }
    }

    .react-select--is-disabled {
      .react-select__control {
        border-color: var(--gray-color);
        background: var(--gray-color);

        .react-select__single-value {
          color: var(--grayscale-200);
        }

        .react-select__input {
          color: var(--grayscale-200) !important;
        }

        .react-select__indicator {
          .icon {
            color: var(--grayscale-200);
          }
        }
      }
    }
  }

  .select.no-outline {
    .react-select {
      .react-select__control {
        border: none;
      }
    }

    .react-select--is-disabled {
      .react-select__control {
        border-color: var(--gray-color);
        background: var(--gray-color);

        .react-select__single-value {
          color: var(--grayscale-200);
        }

        .react-select__input {
          color: var(--grayscale-200) !important;
        }

        .react-select__indicator {
          .icon {
            color: var(--grayscale-200);
          }
        }
      }
    }
  }

  .select.gray {
    .react-select {
      .react-select__control {
        border-color: var(--gray-color);
        background: var(--gray-color);

        .react-select__single-value {
          color: var(--grayscale-300);
        }

        .react-select__input {
          color: var(--grayscale-300) !important;
        }

        &.react-select__control--is-focused {
          background: var(--grayscale-100);
        }

        .react-select__indicator {
          .icon {
            color: var(--grayscale-300);
          }
        }
      }

      .react-select__menu {
        background: var(--gray-color);
        .react-select__menu-list {
          .react-select__option {
            color: var(--grayscale-300);

            &.react-select__option--is-focused {
              background: var(--grayscale-100);
              color: var(--grayscale-300);
            }

            &.react-select__option--is-selected {
              background: var(--grayscale-200);
              color: var(--grayscale-300);
            }
          }

          .react-select__menu-notice {
            color: var(--grayscale-300);
          }
        }
      }
    }

    .react-select--is-disabled {
      .react-select__control {
        border-color: var(--gray-color);
        background: var(--gray-color);

        .react-select__single-value {
          color: var(--grayscale-200);
        }

        .react-select__input {
          color: var(--grayscale-200) !important;
        }

        .react-select__indicator {
          .icon {
            color: var(--grayscale-200);
          }
        }
      }
    }
  }

  .select.primary {
    .react-select {
      .react-select__control {
        &.react-select__control--is-focused {
          border-color: var(--primary-color);
        }

        &:hover {
          border-color: var(--primary-color);
        }
      }
    }

    .react-select--is-disabled {
      .react-select__control {
        border-color: var(--gray-color);
        background: var(--gray-color);

        .react-select__single-value {
          color: var(--grayscale-200);
        }

        .react-select__input {
          color: var(--grayscale-200) !important;
        }

        .react-select__indicator {
          .icon {
            color: var(--grayscale-200);
          }
        }
      }
    }
  }
`;

export default SelectThemes;
