import React from 'react';
import ReactLoading from 'react-loading';
import Text from 'components/atom/Text';
import { StyledLoading } from './styles';
import { AvailableLoadingTypes } from './types';
import { AvailableColorTypes } from 'styles/global-styles';

interface LoadingProps {
  type?: keyof typeof AvailableLoadingTypes;
  color?: keyof typeof AvailableColorTypes;
  height?: string;
  width?: string;
  text?: string;
  className?: string;
}

const Loading: React.FC<LoadingProps> = ({
  type = 'spin',
  color = 'primary-color',
  height = '52px',
  width = '52px',
  text = null,
  className = '',
}) => {
  return (
    <StyledLoading className={`loading ${className}`}>
      <ReactLoading
        type={type}
        color={`var(--${color})`}
        height={height}
        width={width}
      />
      {text && <Text color={color}>{text}</Text>}
    </StyledLoading>
  );
};

export default Loading;
