import React from 'react';
import { useNavigate } from 'react-router-dom';

import Header from 'components/atom/Header';
import Logo from 'components/molecule/Logo';
import Button from 'components/molecule/Button';
import Icon from 'components/atom/Icon';

import { StyledErrorLayout } from './styles';

interface ErrorLayoutProps {
  children?: React.ReactNode;
}

const ErrorLayout: React.FC<ErrorLayoutProps> = ({ children }) => {
  const navigate = useNavigate();

  return (
    <StyledErrorLayout>
      <Header
        leftSideContent={
          <div className="back-logo">
            <Button
              theme="link-dark-gray"
              rounded="true"
              size="small"
              onClick={() => navigate(-1)}
            >
              <Icon name="left-solid-arrow" />
            </Button>
            <Logo />
          </div>
        }
      />
      {children}
    </StyledErrorLayout>
  );
};

export default ErrorLayout;
