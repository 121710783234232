import { useEffect } from 'react';

import { setDefaultOptions } from 'date-fns';
import { registerLocale, setDefaultLocale } from 'react-datepicker';

import { useAppSelector } from 'store/hooks';

import { getI18NextLanguage } from 'services/language';

import ClientState from 'store/slices/client/types';
import { getLocaleISOCode } from 'utils/language';

const useDatePickerConfig = () => {
  const client: ClientState = useAppSelector((state) => state.client);
  const currentLanguage = getI18NextLanguage();

  const setPickerLocale = async (language: string) => {
    if (!language) return;
    const localeISOCode = getLocaleISOCode(language.toLocaleLowerCase());
    const languageLocale = await import(
      /* webpackMode: "lazy" */
      `date-fns/locale/${localeISOCode}/index.js`
    );

    // Set `date-fns` locale globally
    setDefaultOptions({ locale: languageLocale });

    // Set `React DatePicker` locale globally
    registerLocale(localeISOCode, languageLocale);
    setDefaultLocale(localeISOCode);
  };

  useEffect(() => {
    const { language } = client;
    setPickerLocale(currentLanguage || language);
  }, [client, currentLanguage]);
};

export default useDatePickerConfig;
