import { createSlice } from '@reduxjs/toolkit';

import ModalState from './types';

const initialState: ModalState = {
  show: false,
  width: '600px',
  content: '',
  disableBackgroundClick: false,
  onAfterClose: undefined,
  hideCloseButton: false,
};

export const ModalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setModalView: (state, action) => {
      const {
        show,
        width,
        content,
        disableBackgroundClick,
        onAfterClose,
        hideCloseButton,
      } = action.payload;

      state.show = show;
      state.width = width;
      state.content = content;
      state.disableBackgroundClick = disableBackgroundClick;
      state.onAfterClose = onAfterClose;
      state.hideCloseButton = hideCloseButton;
    },
    closeModal: (state) => {
      const {
        show,
        width,
        content,
        disableBackgroundClick,
        onAfterClose,
        hideCloseButton,
      } = initialState;

      state.show = show;
      state.width = width;
      state.content = content;
      state.disableBackgroundClick = disableBackgroundClick;
      state.onAfterClose = onAfterClose;
      state.hideCloseButton = hideCloseButton;
    },
  },
});

export const { setModalView, closeModal } = ModalSlice.actions;

export default ModalSlice.reducer;
