import { css } from 'styled-components';

const DatePickerThemes = css`
  .datepicker {
    &.gray {
      .input-content {
        border-color: var(--gray-color);
        background: var(--gray-color);
        input {
          background: var(--gray-color);
        }
        &:focus-within {
          border-color: var(--gray-color);
        }
        &.has-error {
          border-color: var(--danger-color);
        }
        &.disabled {
          background: var(--grayscale-100);
          border-color: var(--grayscale-100);
          input {
            background: var(--grayscale-100);
          }
        }
        &.disabled:focus-within {
          border-color: var(--grayscale-100);
        }
      }
    }

    &.post {
      .input-content {
        border: none;
        background: none;
        input {
          background: none;
        }
        &.disabled {
          background: var(--gray-color);
          border: 1px solid var(--gray-color);
          input {
            background: var(--gray-color);
          }
        }
        &.disabled:focus-within {
          border: 1px solid var(--gray-color);
        }
      }
    }

    &.shadow {
      .input-content {
        border-color: var(--white-color);
        box-shadow: 0px 0px 32px 4px rgba(0, 0, 0, 0.1);
        input {
          background: var(--white-color);
        }
        &.has-error {
          border-color: var(--danger-color);
          box-shadow: none;
        }
        &.disabled {
          box-shadow: none;
          background: var(--gray-color);
          border: 1px solid var(--gray-color);
          input {
            background: var(--gray-color);
          }
        }
      }
    }

    &.secondary-dark {
      .input-content {
        background: var(--grayscale-400);
        border-color: var(--grayscale-400);
        & > i {
          color: var(--secondary-color);
        }
        input {
          background: var(--grayscale-400);
          color: var(--white-color);
        }
        &.disabled {
          background: var(--grayscale-300);
          border-color: var(--grayscale-300);
          & > i {
            color: var(--grayscale-200);
          }
          input {
            background: var(--grayscale-300);
          }
        }
      }
    }

    &.dark {
      .input-content {
        background: var(--grayscale-400);
        border-color: var(--grayscale-400);
        & > i {
          color: var(--grayscale-200);
        }
        input {
          background: var(--grayscale-400);
          color: var(--white-color);
        }
        &.disabled {
          background: var(--grayscale-300);
          border-color: var(--grayscale-300);
          & > i {
            color: var(--grayscale-200);
          }
          input {
            background: var(--grayscale-300);
          }
        }
      }
    }
  }
`;

export default DatePickerThemes;
