import styled from 'styled-components';

export const StyledLogo = styled.div`
  display: flex;
  align-items: center;

  .tag {
    width: 32px;
    height: 32px;
    padding: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;

    .icon {
      font-size: 22px;
    }
  }

  img {
    width: 90px;
  }

  &.large {
    .tag {
      width: 40px;
      height: 40px;
    }

    .icon {
      font-size: 28px;
    }

    img {
      width: 156px;
    }
  }
`;
