interface LocaleCode {
  [key: string]: string;
}

export const getLocaleISOCode = (language: string) => {
  const locales: LocaleCode = {
    'af-za': 'af-ZA',
    'am-et': 'am-ET',
    'ar-ae': 'ar-AE',
    'ar-bh': 'ar-BH',
    'ar-dz': 'ar-DZ',
    'ar-eg': 'ar-EG',
    'ar-iq': 'ar-IQ',
    'ar-jo': 'ar-JO',
    'ar-kw': 'ar-KW',
    'ar-lb': 'ar-LB',
    'ar-ly': 'ar-LY',
    'ar-ma': 'ar-MA',
    'ar-om': 'ar-OM',
    'ar-qa': 'ar-QA',
    'ar-sa': 'ar-SA',
    'ar-sy': 'ar-SY',
    'ar-tn': 'ar-TN',
    'ar-ye': 'ar-YE',
    'arn-cl': 'arn-CL',
    'as-in': 'as-IN',
    'az-cyrl-az': 'az-Cyrl-AZ',
    'az-latn-az': 'az-Latn-AZ',
    'ba-ru': 'ba-RU',
    'be-by': 'be-BY',
    'bg-bg': 'bg-BG',
    'bn-bd': 'bn-BD',
    'bn-in': 'bn-IN',
    'bo-cn': 'bo-CN',
    'br-fr': 'br-FR',
    'bs-cyrl-ba': 'bs-Cyrl-BA',
    'bs-latn-ba': 'bs-Latn-BA',
    'ca-es': 'ca-ES',
    'co-fr': 'co-FR',
    'cs-cz': 'cs-CZ',
    'cy-gb': 'cy-GB',
    'da-dk': 'da-DK',
    'de-at': 'de-AT',
    'de-ch': 'de-CH',
    'de-de': 'de-DE',
    'de-li': 'de-LI',
    'de-lu': 'de-LU',
    'dsb-de': 'dsb-DE',
    'dv-mv': 'dv-MV',
    'el-gr': 'el-GR',
    'en-029': 'en-029',
    'en-au': 'en-AU',
    'en-bz': 'en-BZ',
    'en-ca': 'en-CA',
    'en-gb': 'en-GB',
    'en-ie': 'en-IE',
    'en-in': 'en-IN',
    'en-jm': 'en-JM',
    'en-my': 'en-MY',
    'en-nz': 'en-NZ',
    'en-ph': 'en-PH',
    'en-sg': 'en-SG',
    'en-tt': 'en-TT',
    'en-us': 'en-US',
    'en-za': 'en-ZA',
    'en-zw': 'en-ZW',
    'es-ar': 'es-AR',
    'es-bo': 'es-BO',
    'es-cl': 'es-CL',
    'es-co': 'es-CO',
    'es-cr': 'es-CR',
    'es-do': 'es-DO',
    'es-ec': 'es-EC',
    'es-es': 'es-ES',
    'es-gt': 'es-GT',
    'es-hn': 'es-HN',
    'es-mx': 'es-MX',
    'es-ni': 'es-NI',
    'es-pa': 'es-PA',
    'es-pe': 'es-PE',
    'es-pr': 'es-PR',
    'es-py': 'es-PY',
    'es-sv': 'es-SV',
    'es-us': 'es-US',
    'es-uy': 'es-UY',
    'es-ve': 'es-VE',
    'et-ee': 'et-EE',
    'eu-es': 'eu-ES',
    'fa-ir': 'fa-IR',
    'fi-fi': 'fi-FI',
    'fil-ph': 'fil-PH',
    'fo-fo': 'fo-FO',
    'fr-be': 'fr-BE',
    'fr-ca': 'fr-CA',
    'fr-ch': 'fr-CH',
    'fr-fr': 'fr-FR',
    'fr-lu': 'fr-LU',
    'fr-mc': 'fr-MC',
    'fy-nl': 'fy-NL',
    'ga-ie': 'ga-IE',
    'gd-gb': 'gd-GB',
    'gl-es': 'gl-ES',
    'gsw-fr': 'gsw-FR',
    'gu-in': 'gu-IN',
    'ha-latn-ng': 'ha-Latn-NG',
    'he-il': 'he-IL',
    'hi-in': 'hi-IN',
    'hr-ba': 'hr-BA',
    'hr-hr': 'hr-HR',
    'hsb-de': 'hsb-DE',
    'hu-hu': 'hu-HU',
    'hy-am': 'hy-AM',
    'id-id': 'id-ID',
    'ig-ng': 'ig-NG',
    'ii-cn': 'ii-CN',
    'is-is': 'is-IS',
    'it-ch': 'it-CH',
    'it-it': 'it-IT',
    'iu-cans-ca': 'iu-Cans-CA',
    'iu-latn-ca': 'iu-Latn-CA',
    'ja-jp': 'ja-JP',
    'ka-ge': 'ka-GE',
    'kk-kz': 'kk-KZ',
    'kl-gl': 'kl-GL',
    'km-kh': 'km-KH',
    'kn-in': 'kn-IN',
    'ko-kr': 'ko-KR',
    'kok-in': 'kok-IN',
    'ky-kg': 'ky-KG',
    'lb-lu': 'lb-LU',
    'lo-la': 'lo-LA',
    'lt-lt': 'lt-LT',
    'lv-lv': 'lv-LV',
    'mi-nz': 'mi-NZ',
    'mk-mk': 'mk-MK',
    'ml-in': 'ml-IN',
    'mn-mn': 'mn-MN',
    'mn-mong-cn': 'mn-Mong-CN',
    'moh-ca': 'moh-CA',
    'mr-in': 'mr-IN',
    'ms-bn': 'ms-BN',
    'ms-my': 'ms-MY',
    'mt-mt': 'mt-MT',
    'nb-no': 'nb-NO',
    'ne-np': 'ne-NP',
    'nl-be': 'nl-BE',
    'nl-nl': 'nl-NL',
    'nn-no': 'nn-NO',
    'nso-za': 'nso-ZA',
    'oc-fr': 'oc-FR',
    'or-in': 'or-IN',
    'pa-in': 'pa-IN',
    'pl-pl': 'pl-PL',
    'prs-af': 'prs-AF',
    'ps-af': 'ps-AF',
    pt: 'pt-BR',
    'pt-br': 'pt-BR',
    'pt-pt': 'pt-PT',
    'qut-gt': 'qut-GT',
    'quz-bo': 'quz-BO',
    'quz-ec': 'quz-EC',
    'quz-pe': 'quz-PE',
    'rm-ch': 'rm-CH',
    'ro-ro': 'ro-RO',
    'ru-ru': 'ru-RU',
    'rw-rw': 'rw-RW',
    'sa-in': 'sa-IN',
    'sah-ru': 'sah-RU',
    'se-fi': 'se-FI',
    'se-no': 'se-NO',
    'se-se': 'se-SE',
    'si-lk': 'si-LK',
    'sk-sk': 'sk-SK',
    'sl-si': 'sl-SI',
    'sma-no': 'sma-NO',
    'sma-se': 'sma-SE',
    'smj-no': 'smj-NO',
    'smj-se': 'smj-SE',
    'smn-fi': 'smn-FI',
    'sms-fi': 'sms-FI',
    'sq-al': 'sq-AL',
    'sr-cyrl-ba': 'sr-Cyrl-BA',
    'sr-cyrl-cs': 'sr-Cyrl-CS',
    'sr-cyrl-me': 'sr-Cyrl-ME',
    'sr-cyrl-rs': 'sr-Cyrl-RS',
    'sr-latn-ba': 'sr-Latn-BA',
    'sr-latn-cs': 'sr-Latn-CS',
    'sr-latn-me': 'sr-Latn-ME',
    'sr-latn-rs': 'sr-Latn-RS',
    'sv-fi': 'sv-FI',
    'sv-se': 'sv-SE',
    'sw-ke': 'sw-KE',
    'syr-sy': 'syr-SY',
    'ta-in': 'ta-IN',
    'te-in': 'te-IN',
    'tg-cyrl-tj': 'tg-Cyrl-TJ',
    'th-th': 'th-TH',
    'tk-tm': 'tk-TM',
    'tn-za': 'tn-ZA',
    'tr-tr': 'tr-TR',
    'tt-ru': 'tt-RU',
    'tzm-latn-dz': 'tzm-Latn-DZ',
    'ug-cn': 'ug-CN',
    'uk-ua': 'uk-UA',
    'ur-pk': 'ur-PK',
    'uz-cyrl-uz': 'uz-Cyrl-UZ',
    'uz-latn-uz': 'uz-Latn-UZ',
    'vi-vn': 'vi-VN',
    'wo-sn': 'wo-SN',
    'xh-za': 'xh-ZA',
    'yo-ng': 'yo-NG',
    'zh-cn': 'zh-CN',
    'zh-hk': 'zh-HK',
    'zh-mo': 'zh-MO',
    'zh-sg': 'zh-SG',
    'zh-tw': 'zh-TW',
    'zu-za': 'zu-ZA',
  };

  const languageCode = locales[language];

  return languageCode ? languageCode : 'en-US';
};

export const getISO6391Code = (locale: string): string =>
  locale && locale.length >= 2 ? locale.substring(0, 2) : 'en';
