import React from 'react';

import { StyledTag } from './styles';
import { AvailableTagThemes } from './types';

interface TagProps {
  radius?: string;
  padding?: string;
  children?: React.ReactNode;
  className?: string;
  theme?: keyof typeof AvailableTagThemes;
}

const Tag: React.FC<TagProps> = ({
  radius,
  padding,
  theme = 'default',
  className = '',
  children,
}) => {
  return (
    <StyledTag
      radius={radius}
      padding={padding}
      className={`tag ${className} ${theme}`}
    >
      {children}
    </StyledTag>
  );
};

export default Tag;
