import React from 'react';

import { StyledText } from './styles';
import { AvailableTextTypes } from './types';
import { AvailableColorTypes } from 'styles/global-styles';

interface TextProps {
  as?: keyof typeof AvailableTextTypes;
  children?: React.ReactNode;
  color?: keyof typeof AvailableColorTypes;
  weight?: string;
  family?: string;
  align?: string;
  italic?: boolean;
  className?: string;
}

const Text: React.FC<TextProps> = ({
  as = 'p',
  color = 'dark-color',
  weight = '500',
  family = 'Hind',
  align = 'start',
  italic = false,
  className,
  children,
}) => {
  return (
    <StyledText
      as={as}
      color={color}
      weight={weight}
      family={family}
      italic={italic}
      align={align}
      className={`${className ? className : ''} text`}
    >
      {children}
    </StyledText>
  );
};

export default Text;
