import styled from 'styled-components';

interface GridRowProps {
  justifyContent?: string | undefined;
  alignItems?: string | undefined;
  textAlign?: string | undefined;
}
export const GridRow = styled.div.attrs(() => ({
  className: 'grid-row',
}))<GridRowProps>`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  align-items: ${({ alignItems }) => alignItems || 'flex-start'};
  text-align: ${({ textAlign }) => textAlign || 'left'};
  flex-wrap: wrap;
`;

export default GridRow;
