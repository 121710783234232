import Card from 'components/atom/Card';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Text from 'components/atom/Text';

import { StyledForm, StyledFormHeight100 } from '../FormStyles/styles';
import PageLoading from 'components/molecule/PageLoading';

import { getFormData } from 'apis/forms/api';

import rectangleCompleted from 'assets/images/rectangle_completed.png';

const Form: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    status: false,
    company: '',
    start_at: '',
    finish_at: '',
    embed_url: '',
  });

  const { token } = useParams();
  useEffect(() => {
    //check form validity
    getFormData(token as string)
      .then((response) => {
        const data = response.data;
        setFormData(() => {
          return {
            status: true,
            ...data,
          };
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const dateConverted = (dateReceived: string) => {
    const formatTwoDigits = (digit: number) => ('0' + digit).slice(-2);
    const date = new Date(dateReceived);
    return `${formatTwoDigits(date.getDate())}/${formatTwoDigits(
      date.getMonth() + 1,
    )}/${date.getFullYear()} às ${formatTwoDigits(
      date.getHours(),
    )}:${formatTwoDigits(date.getMinutes())}h`;
  };

  let content = (
    <StyledFormHeight100>
      <PageLoading />
    </StyledFormHeight100>
  );

  if (!loading) {
    if (formData.status === false) {
      content = (
        <StyledFormHeight100>
          <Card noBorder="true">
            <img src={rectangleCompleted} alt="Rectangle completed" />
            <Text as="h4" align="center" weight="600">
              Questionário terminado!
            </Text>
            <Text as="p" align="center" color="grayscale-300">
              Agradecemos a sua colaboração.
            </Text>
          </Card>
        </StyledFormHeight100>
      );
    } else {
      content = (
        <StyledForm>
          <div>
            <div className="text-container">
              <Text as="h3" align="left" weight="700" color="grayscale-400">
                {formData.company}
              </Text>
              <Text as="h5" align="left" weight="600" color="grayscale-300">
                Pode responder até {dateConverted(formData.finish_at)}
              </Text>
              <Text as="p" align="left" weight="500">
                Olá, foi convidado(a) a responder a um inquérito sobre a
                felicidade no trabalho enquanto colaborador(a) do(a){' '}
                {formData.company}.<br />O inquérito é anónimo e a sua
                identidade não será revelada. Os resultados ajudarão o(a){' '}
                {formData.company} a trabalhar na melhoria contínua da sua
                experiência de trabalho.
              </Text>
            </div>

            <Card noBorder="true" className="card">
              <div>
                <iframe
                  src={formData.embed_url}
                  loading="lazy"
                  width="100%"
                  title="Inquérito de Felicidade no Trabalho"
                ></iframe>
              </div>
            </Card>
          </div>
        </StyledForm>
      );
    }
  }

  return content;
};

export default Form;
