import styled from 'styled-components';

interface GridContainerProps {
  fluid?: string | undefined;
}

const GridContainer = styled.div.attrs(() => ({
  className: 'grid-container',
}))<GridContainerProps>`
  width: 100%;
  max-width: ${(props) => (props.fluid ? '100%' : '1366px')};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
`;

export default GridContainer;
