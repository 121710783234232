import { createSlice } from '@reduxjs/toolkit';

import { OrganizationState } from './types';

const initialState: OrganizationState = {
  avatar: null,
  client: null,
  color_scheme: null,
  legacy_menu: [],
  modules: [],
  name: '',
  pk: '',
  timezone: '',
  timezone_utc: '',
};

export const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    setOrganization: (state, action) => {
      const {
        avatar,
        client,
        color_scheme,
        legacy_menu,
        modules,
        name,
        pk,
        timezone,
        timezone_utc,
      } = action.payload;

      state.avatar = avatar;
      state.client = client;
      state.color_scheme = color_scheme;
      state.legacy_menu = legacy_menu;
      state.modules = modules;
      state.name = name;
      state.pk = pk;
      state.timezone = timezone;
      state.timezone_utc = timezone_utc;
    },
    clearOrganization: (state) => {
      const {
        avatar,
        client,
        color_scheme,
        legacy_menu,
        modules,
        name,
        pk,
        timezone,
        timezone_utc,
      } = initialState;

      state.avatar = avatar;
      state.client = client;
      state.color_scheme = color_scheme;
      state.legacy_menu = legacy_menu;
      state.modules = modules;
      state.name = name;
      state.pk = pk;
      state.timezone = timezone;
      state.timezone_utc = timezone_utc;
    },
  },
});

export const { setOrganization, clearOrganization } = organizationSlice.actions;

export default organizationSlice.reducer;
