import { createSlice } from '@reduxjs/toolkit';

import OrganizationsState from './types';

const initialState: OrganizationsState = {
  list: [],
};

export const userSlice = createSlice({
  name: 'organizations',
  initialState,
  reducers: {
    setOrganizations: (state, action) => {
      state.list = action.payload.list;
    },
  },
});

export const { setOrganizations } = userSlice.actions;

export default userSlice.reducer;
