import styled from 'styled-components';

interface StyledTagProps {
  radius?: string;
  padding?: string;
}

export const StyledTag = styled.div<StyledTagProps>`
  width: max-content;
  background: var(--gray-color);
  padding: ${({ padding }) => (padding ? padding : '6px 12px')};
  border-radius: ${({ radius }) => (radius ? radius : '100px')};
`;
