import styled from 'styled-components';

import background from 'assets/images/background.svg';

export const StyledForm = styled.div`
  width: 100%;
  // height: 100%;
  min-height: calc(100% - 72px);
  background: var(--gray-color);
  background-image: url('${background}');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: -150px 125%;
  padding: 16px;

  display: flex;
  justify-content: center;
  // align-items: center;

  h4 {
    margin-top: 1rem;
  }

  h5 {
    margin-top: 0.5rem;
  }

  p {
    margin-top: 1rem;
  }

  .contact-dashboard-text {
    margin-top: 0.5rem;
    line-height: 20px;
  }

  .card {
    width: 100%;
    max-width: 1000px;
    padding: 64px 46px;

    .logo-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      position: relative;
      margin-bottom: 64px;

      .image {
        max-width: 204px;
      }
    }
    iframe {
      // margin-top: 30px;
    }
  }

  .card:not(:first-child) {
    margin-top: 20px;
  }

  .text-container {
    max-width: 1000px;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .wrap-container {
    width: 100%;
    max-width: 1000px;
  }

  @media (max-width: 720px) {
    .card {
      padding: 32px 16px;

      .logo-wrapper {
        & > .tag {
          top: -25px;
          right: -8px;
        }
      }
    }
  }
`;

export const StyledFormHeight100 = styled.div`
  width: 100%;
  height: calc(100% - 72px);
  background: var(--gray-color);
  background-image: url('${background}');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: -150px 125%;
  padding: 16px;

  display: flex;
  justify-content: center;
  align-items: center;

  h4 {
    //margin-top: 1rem;
  }

  h5 {
    margin-top: 0.5rem;
  }

  p {
    margin-top: 0.5rem;
  }

  img {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 310px;
  }

  .card {
    width: 100%;
    max-width: 1000px;
    padding: 64px 46px;

    .logo-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      position: relative;
      margin-bottom: 64px;

      .image {
        max-width: 204px;
      }
    }
    iframe {
      // margin-top: 30px;
    }
  }

  .text-container {
    max-width: 1000px;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  @media (max-width: 720px) {
    .card {
      padding: 32px 16px;

      .logo-wrapper {
        & > .tag {
          top: -25px;
          right: -8px;
        }
      }
    }
  }
`;
