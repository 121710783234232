import { doLogin } from 'apis/forms/api';
import PageLoading from 'components/molecule/PageLoading';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { setAccessToken } from 'services/auth';

const LoginCallback: React.FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const identity = searchParams.get('identity');
    const idp = searchParams.get('idp');
    if (idp && identity) {
      doLogin(idp, identity)
        .then((response) => {
          setAccessToken(response.data.access);
          navigate('/staff');
        })
        .catch(() => {
          navigate('/login', { state: { error: 'Credenciais inválidas!' } });
        });
    }
  }, []);

  return <PageLoading />;
};

export default LoginCallback;
