import React, { useEffect, useCallback, useRef } from 'react';
import { ThemeProvider } from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { defaultClientName } from 'settings';

import useDateFormatterConfig from 'hooks/general/useDateFormatterConfig';
import useDatePickerConfig from 'hooks/general/useDatePickerConfig';

import { closeModal } from 'store/slices/modal';

import {
  getRefreshToken,
  getClientSlug,
  setAccessToken,
  logout,
} from 'services/auth';

import { useAppSelector, useAppDispatch } from 'store/hooks';

import { setAppLoading } from 'store/slices/appLoading';

import GlobalStyles from 'styles/global-styles';

import { refreshToken } from 'apis/auth';

import Toaster from 'components/organism/Toaster';
import PageLoading from 'components/molecule/PageLoading';
import AppLoading from 'components/organism/AppLoading';
import Modal from 'components/molecule/Modal';

import { StyledMainLayout } from './styles';
import ToasterState from 'store/slices/toaster/types';
import ThemeState from 'store/slices/theme/types';
import ModalState from 'store/slices/modal/types';

const MainLayout: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
  children,
}) => {
  const effectRan = useRef(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  useDateFormatterConfig();
  useDatePickerConfig();

  const urlParts = window.location.href.split('//')[1].split('.');
  const urlClientSlug = urlParts.length > 1 ? urlParts[0] : defaultClientName;

  const storedRefreshToken = getRefreshToken();
  const storedClientSlug = getClientSlug();

  const theme: ThemeState = useAppSelector((state) => state.theme);
  const toaster: ToasterState = useAppSelector((state) => state.toaster);
  const showPageLoading: boolean = useAppSelector(
    (state) => state.pageLoading.show,
  );
  const showAppLoading: boolean = useAppSelector(
    (state) => state.appLoading.show,
  );
  const modal: ModalState = useAppSelector((state) => state.modal);

  const handleGetClientConfig = useCallback(() => {
    dispatch(setAppLoading(false));
  }, []);

  const closedModalInBrowserBackButton = useCallback(() => {
    window.addEventListener(
      'popstate',
      function () {
        dispatch(closeModal());
      },
      false,
    );
  }, [dispatch]);

  useEffect(() => {
    if (effectRan.current) {
      return;
    }

    closedModalInBrowserBackButton();

    if (!storedRefreshToken) {
      handleGetClientConfig();
      return;
    }

    refreshToken(storedRefreshToken)
      .then((response) => {
        setAccessToken(response.data.access);
        handleGetClientConfig();
      })
      .catch((error) => {
        if (!error.response) {
          navigate('/error-500');
          dispatch(setAppLoading(false));
          return;
        }

        logout();
        handleGetClientConfig();
      });

    return () => {
      effectRan.current = true;
    };
  }, [
    handleGetClientConfig,
    showAppLoading,
    navigate,
    dispatch,
    storedClientSlug,
    storedRefreshToken,
    closedModalInBrowserBackButton,
  ]);

  return (
    <StyledMainLayout>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <Toaster
          show={toaster.show.toString()}
          time={toaster.time}
          icon={toaster.icon}
          title={toaster.title}
          description={toaster.description}
          theme={toaster.theme}
          customLeft={toaster.customLeft}
          hideCloseButton={toaster.hideCloseButton}
        >
          {toaster.children}
        </Toaster>
        {showPageLoading && <PageLoading />}

        {showAppLoading && <AppLoading />}

        {modal.show && (
          <Modal
            width={modal.width}
            content={modal.content}
            disableBackgroundClick={modal.disableBackgroundClick}
            onAfterClose={modal.onAfterClose}
            hideCloseButton={modal.hideCloseButton}
          />
        )}

        {!showAppLoading && children}
      </ThemeProvider>
    </StyledMainLayout>
  );
};

export default MainLayout;
