import { css } from 'styled-components';

const TagThemes = css`
  .tag {
    &.primary-100 {
      background: var(--primary-color-100);
      color: var(--dark-color);

      & > .text,
      & > p {
        color: var(--dark-color);
      }
    }

    &.dark {
      background: var(--dark-color);
      color: var(--white-color);

      & > .text,
      & > p {
        color: var(--white-color);
      }
    }

    &.white {
      background: var(--white-color);
      border: 1px solid var(--dark-color);
      color: var(--dark-color);

      & > .text,
      & > p {
        color: var(--dark-color);
      }
    }

    &.primary {
      background: var(--primary-color);
      color: var(--white-color);

      & > .text,
      & > p {
        color: var(--white-color);
      }
    }

    &.primary-300 {
      background: var(--primary-color-300);
      color: var(--white-color);

      & > .text,
      & > p {
        color: var(--white-color);
      }
    }

    &.secondary {
      background: var(--secondary-color);
      color: var(--white-color);

      & > .text,
      & > p {
        color: var(--white-color);
      }
    }

    &.secondary-200 {
      background: var(--secondary-color-200);
      color: var(--white-color);

      & > .text,
      & > p {
        color: var(--white-color);
      }
    }

    &.tertiary-1 {
      background: var(--tertiary-color-1);
      color: var(--white-color);

      & > .text,
      & > p {
        color: var(--white-color);
      }
    }

    &.support-pastel-2 {
      background: var(--support-pastel-color-2);
      color: var(--white-color);

      & > .text,
      & > p {
        color: var(--white-color);
      }
    }

    &.gray-200 {
      background: var(--grayscale-200);
      color: var(--white-color);

      & > .text,
      & > p {
        color: var(--white-color);
      }
    }

    &.gray-300 {
      background: var(--grayscale-300);
      color: var(--white-color);

      & > .text,
      & > p {
        color: var(--white-color);
      }
    }

    &.success-100 {
      background: var(--success-color-100);
      color: var(--success-color-300);

      & > .text,
      & > p {
        color: var(--success-color-300);
      }
    }

    &.danger-100 {
      background: var(--danger-color-100);
      color: var(--danger-color-300);

      & > .text,
      & > p {
        color: var(--danger-color-300);
      }
    }

    &.warning-100 {
      background: var(--warning-color-100);
      color: var(--danger-color);

      & > .text,
      & > p {
        color: var(--danger-color);
        opacity: 60%;
      }
    }
  }
`;

export default TagThemes;
