import { createSlice } from '@reduxjs/toolkit';

import ClientState from './types';

const initialState: ClientState = {
  avatar: null,
  id: null,
  language: '',
  logo: null,
  name: '',
  slug: '',
  timezone: '',
  timezone_utc: '',
};

export const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    setClientConfig: (state, action) => {
      const { avatar, id, language, logo, name, slug, timezone, timezone_utc } =
        action.payload;

      state.avatar = avatar;
      state.id = id;
      state.language = language;
      state.logo = logo;
      state.name = name;
      state.slug = slug;
      state.timezone = timezone;
      state.timezone_utc = timezone_utc;
    },
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
  },
});

export const { setClientConfig, setLanguage } = clientSlice.actions;

export default clientSlice.reducer;
