import {
  ACCESS_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
  CLIENT_SLUG_KEY,
  CURRENT_ORGANIZATION_ID_KEY,
} from './storageKeys';

export const setAccessToken = (token: string) =>
  localStorage.setItem(ACCESS_TOKEN_KEY, token);

export const getAccessToken = () => localStorage.getItem(ACCESS_TOKEN_KEY);

export const setRefreshToken = (token: string) =>
  localStorage.setItem(REFRESH_TOKEN_KEY, token);

export const getRefreshToken = () => localStorage.getItem(REFRESH_TOKEN_KEY);

export const setClientSlug = (slug: string) =>
  localStorage.setItem(CLIENT_SLUG_KEY, slug);

export const getClientSlug = () => localStorage.getItem(CLIENT_SLUG_KEY);

export const setCurrentOrganizationId = (organizationId: string) =>
  localStorage.setItem(CURRENT_ORGANIZATION_ID_KEY, organizationId);

export const getCurrentOrganizationId = () =>
  localStorage.getItem(CURRENT_ORGANIZATION_ID_KEY);

export const login = (
  accessToken: string,
  refreshToken: string,
  clientSlug: string,
) => {
  setAccessToken(accessToken);
  setRefreshToken(refreshToken);
  setClientSlug(clientSlug);
};

export const logout = () => {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
  localStorage.removeItem(CLIENT_SLUG_KEY);
  localStorage.removeItem(CURRENT_ORGANIZATION_ID_KEY);
};
