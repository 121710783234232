import axios, { AxiosRequestConfig } from 'axios';
import { getAccessToken } from 'services/auth';

export const api = axios.create({
  // baseURL: 'https://ms.i18n.useflow.tech', // change to api url
  baseURL: 'https://ms.forms.useflow.tech',
});

api.interceptors.request.use(async (config: AxiosRequestConfig) => {
  const token = getAccessToken();

  if (!token || !config.headers) {
    return config;
  }

  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

export const getFormData = (token: string) => {
  const url = `/api/v1/forms/${token}/`;
  return api.get(url);
};

export const getFormStats = (token: string) => {
  const url = `/api/v1/forms/${token}/stats/`;
  return api.get(url);
};

export const doLogin = (idp: string, identity: string) => {
  const url = '/api/v1/sso/signin/';
  return api.post(url, {
    idp: idp,
    identity: identity,
  });
};

export const getStaffDashboard = () => {
  const url = '/api/v1/staff/forms/';
  return api.get(url);
};

export const getFormIdStats = (formId: string) => {
  const url = `/api/v1/staff/forms/${formId}/`;
  return api.get(url);
};

export default api;
