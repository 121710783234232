import React, { Suspense } from 'react';
import { Provider as ReduxProvider } from 'react-redux';

// import MainLayout from 'layouts/MainLayout';
import PageLoading from 'components/molecule/PageLoading';

import store from 'store';

import AppRoutes from 'routes';

import 'styles/fonts.css';

function App() {
  return (
    <Suspense fallback={<PageLoading />}>
      <ReduxProvider store={store}>
        <AppRoutes />
      </ReduxProvider>
    </Suspense>
  );
}

export default App;
