import { css } from 'styled-components';

const AlertThemes = css`
  .alert {
    &.success {
      background: var(--success-color);
      border-color: var(--success-color);
      .alert-info {
        .icon {
          background: var(--white-color);
          color: var(--success-color);
        }
        .text,
        .description {
          color: var(--white-color);
        }
      }
      .alert-close-button {
        .icon {
          color: var(--white-color);
        }
      }
    }

    &.danger {
      background: var(--danger-color);
      border-color: var(--danger-color);
      .alert-info {
        .icon {
          background: var(--white-color);
          color: var(--danger-color);
        }
        .text,
        .description {
          color: var(--white-color);
        }
      }
      .alert-close-button {
        .icon {
          color: var(--white-color);
        }
      }
    }

    &.danger-100 {
      background: var(--danger-color-100);
      border-color: var(--danger-color-100);
      .alert-info {
        .icon {
          background: var(--white-color);
          color: var(--danger-color);
        }
        .text,
        .description {
          color: var(--danger-color);
        }
      }
      .alert-close-button {
        .icon {
          color: var(--danger-color);
        }
      }
    }
  }
`;

export default AlertThemes;
