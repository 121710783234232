import styled from 'styled-components';
import { AvailableIcons, StyledIconProps } from './types';

const BaseStyledIcons = styled.i`
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 120%;
  line-height: 1;
  font-weight: 500;

  font-family: UseflowIcons, system-ui !important;
  ${Object.entries(AvailableIcons).map(([iconName, iconCode]) => {
    return `&.${iconName}:before { content: '${iconCode}'; }`;
  })}
`;

export const StyledIcon = styled(BaseStyledIcons).attrs(
  (props: StyledIconProps) => ({
    className: `${props.name} icon`,
    color: props.color,
  }),
)<StyledIconProps>`
  color: var(--${(props) => props.color});
`;
