import { css } from 'styled-components';

export const AvailableAvatarThemeTypes = {
  default: 'default',
  primary: 'primary',
  secondary: 'secondary',
  'tertiary-1': 'tertiary-1',
  'tertiary-3': 'tertiary-3',
  'support-pastel-2': 'support-pastel-2',
};

export const AvatarThemes = css`
  .avatar {
    &.primary {
      .icon-avatar {
        background: var(--primary-color);
      }
      .icon {
        color: var(--primary-color);
      }
    }

    &.secondary {
      .icon-avatar {
        background: var(--secondary-color);
      }
      .icon {
        color: var(--secondary-color);
      }
    }

    &.tertiary-1 {
      .icon-avatar {
        background: var(--tertiary-color-1);
      }
      .icon {
        color: var(--tertiary-color-1);
      }
    }

    &.tertiary-3 {
      .icon-avatar {
        background: var(--tertiary-color-3);
      }
      .icon {
        color: var(--tertiary-color-3);
      }
    }

    &.support-pastel-2 {
      .icon-avatar {
        background: var(--support-pastel-color-2);
      }
      .icon {
        color: var(--support-pastel-color-2);
      }
    }
  }
`;

export default AvatarThemes;
