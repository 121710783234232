import { createSlice } from '@reduxjs/toolkit';

import ToasterState from './types';

const initialState: ToasterState = {
  show: false,
  time: 10000,
  title: '',
  description: '',
  icon: '',
  theme: 'success',
  customLeft: '156px',
  children: '',
  hideCloseButton: false,
};

const getState = (
  state: ToasterState,
  payload: ToasterState,
  show: boolean,
) => {
  const {
    time,
    title,
    description,
    icon,
    theme,
    customLeft,
    children,
    hideCloseButton,
  } = payload;

  state.show = show;
  state.time = time;
  state.title = title;
  state.description = description;
  state.icon = icon;
  state.theme = theme;
  state.customLeft = customLeft;
  state.children = children;
  state.hideCloseButton = hideCloseButton;
};

export const toasterSlice = createSlice({
  name: 'toaster',
  initialState,
  reducers: {
    showMessage: (state, action) => {
      getState(state, action.payload, true);
    },
    closeMessage: (state) => {
      getState(state, initialState, false);
    },
  },
});

export const { showMessage, closeMessage } = toasterSlice.actions;

export default toasterSlice.reducer;
