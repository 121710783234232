import Card from 'components/atom/Card';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Text from 'components/atom/Text';

import { StyledForm, StyledFormHeight100 } from '../FormStyles/styles';
import PageLoading from 'components/molecule/PageLoading';
import { getFormIdStats } from 'apis/forms/api';
import { getAccessToken, logout } from 'services/auth';

const DashboardClient: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [formIdData, setFormIdData] = useState({
    status: false,
    name: '',
    email: '',
    phone_number: null,
    company: '',
    employees: null,
    dashboards: [''],
    start_at: '',
    finish_at: '',
  });
  const navigate = useNavigate();

  const { formId } = useParams();

  useEffect(() => {
    const accessToken = getAccessToken();
    if (!accessToken) {
      navigate('/login');
    } else {
      getFormIdStats(formId as string)
        .then((response) => {
          const data = response.data;
          setFormIdData(() => {
            return {
              status: true,
              ...data,
            };
          });
        })
        .catch((err) => {
          if (err.response.status === 401) {
            logout();
            navigate('/login');
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  const dateConverted = (dateReceived: string) => {
    const formatTwoDigits = (digit: number) => ('0' + digit).slice(-2);
    const date = new Date(dateReceived);
    return `${formatTwoDigits(date.getDate())}/${formatTwoDigits(
      date.getMonth() + 1,
    )}/${date.getFullYear()} às ${formatTwoDigits(
      date.getHours(),
    )}:${formatTwoDigits(date.getMinutes())}h`;
  };

  let content = (
    <StyledFormHeight100>
      <PageLoading />
    </StyledFormHeight100>
  );

  const getParagraphText = () => {
    let paragraphText = `Email: ${formIdData.email}\n`;
    if (formIdData.phone_number)
      paragraphText += `Telemóvel: ${formIdData.phone_number}\n`;
    if (formIdData.employees)
      paragraphText += `Funcionários: ${formIdData.employees}\n`;
    paragraphText += `Início: ${dateConverted(formIdData.start_at)}\n`;
    paragraphText += `Fim: ${dateConverted(formIdData.finish_at)}`;

    return paragraphText;
  };

  let dashboards_content;

  if (!loading) {
    if (formIdData.status === false) {
      content = (
        <StyledFormHeight100>
          <Card noBorder="true">
            <Text as="h4" align="center" weight="600">
              Erro ao devolver estatísticas!
            </Text>
          </Card>
        </StyledFormHeight100>
      );
    } else {
      if (
        formIdData.dashboards[0] === '' &&
        formIdData.dashboards.length === 1
      ) {
        dashboards_content = (
          <Card noBorder="true">
            <Text as="h4" align="center" weight="600">
              Sem estatísticas para mostrar!
            </Text>
          </Card>
        );
      } else {
        dashboards_content = formIdData.dashboards.map((dashboard, i) => (
          <Card key={i} noBorder="true">
            <div>
              <iframe
                src={dashboard}
                loading="lazy"
                width="100%"
                height="610px"
                title="Dashboard"
              ></iframe>
            </div>
          </Card>
        ));
      }
      content = (
        <StyledForm>
          <div className="wrap-container">
            <div className="text-container">
              <Text as="h3" align="left" weight="700" color="grayscale-400">
                Estatísticas relativas ao contato {formIdData.company}
              </Text>
              <Text as="h5" align="left" weight="600" color="grayscale-300">
                {formIdData.name}
              </Text>
              <Text
                as="p"
                align="left"
                weight="400"
                className="contact-dashboard-text"
              >
                {getParagraphText()}
              </Text>
            </div>
            {dashboards_content}
          </div>
        </StyledForm>
      );
    }
  }

  return content;
};

export default DashboardClient;
