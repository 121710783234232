import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'store/hooks';

import ClientState from 'store/slices/client/types';

import Card from 'components/atom/Card';
import Logo from 'components/molecule/Logo';
import Button from 'components/molecule/Button';
import Text from 'components/atom/Text';

import Image from 'components/molecule/Image';
import PageLoading from 'components/molecule/PageLoading';

import { StyledLogin } from './styles';
import { useLocation, useNavigate } from 'react-router-dom';

const Login: React.FC = () => {
  const { t } = useTranslation();
  const locState = useLocation();

  const client: ClientState = useAppSelector((state) => state.client);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(
    locState.state !== null ? locState.state.error : '',
  );

  const handleSignin = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    const service = process.env.REACT_APP_SSO_LOGIN_TOKEN;
    window.location.replace(
      `https://ms.auth.useflow.tech/sso/signin/?idp=useflow&service=${service}`,
    );
  };

  return (
    <StyledLogin className="login-page">
      {loading && <PageLoading />}

      <Card noBorder="true">
        <div className="logo-wrapper">
          {client.logo ? <Image src={client.logo} /> : <Logo size="large" />}
        </div>
        <form className="form-wrapper" onSubmit={handleSignin}>
          <div className="button-actions">
            <Button
              theme="primary"
              className="login-button"
              size="big"
              type="submit"
            >
              {t('Login')}
            </Button>
          </div>
          {error && (
            <Text as="p" color="danger-color" align="center" weight="500">
              {error}
            </Text>
          )}
        </form>
      </Card>
    </StyledLogin>
  );
};

export default Login;
