import React from 'react';

import { AvailableColorTypes } from 'styles/global-styles';

import { StyledCard } from './styles';

interface CardProps {
  color?: keyof typeof AvailableColorTypes;
  shadow?: string;
  noBorder?: string;
  className?: string;
  children?: React.ReactNode;
}

const Card: React.FC<CardProps> = ({
  color = 'white-color',
  shadow = 'false',
  noBorder = 'false',
  className = '',
  children,
}) => {
  return (
    <StyledCard
      className={`card ${className}`}
      color={color}
      shadow={shadow}
      noBorder={noBorder}
    >
      {children}
    </StyledCard>
  );
};

export default Card;
