import React from 'react';
import { useTranslation } from 'react-i18next';

import FastMenu from 'components/organism/FastMenu';
import Header from 'components/atom/Header';
import Logo from 'components/molecule/Logo';

import { StyledFormLayout } from './styles';
import { FastMenuOptionProp } from 'components/organism/FastMenu/types';

const FormLayout: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
  children,
}) => {
  const { t } = useTranslation();

  // Fast menu options
  const topOptions: FastMenuOptionProp[] = [];

  const bottomOptions: FastMenuOptionProp[] = [];

  return (
    <StyledFormLayout>
      <div className="menus-wrapper">
        <FastMenu topOptions={topOptions} bottomOptions={bottomOptions} />
      </div>
      <div className="content default-scroll">
        <Header leftSideContent={<Logo />} />
        {children}
      </div>
    </StyledFormLayout>
  );
};

export default FormLayout;
