import styled from 'styled-components';

export const StyledLoading = styled.div`
  div {
    display: block;
    margin: auto;
  }

  .text {
    text-align: center;
    margin-top: 8px;
  }
`;
