import { css } from 'styled-components';

export const AvailableSizeTypes = {
  small: 'small',
  normal: 'normal',
  big: 'big',
};

export const AvailableThemeTypes = {
  dark: 'dark',
  'link-primary': 'link-primary',
  'dark-outline': 'dark-outline',
  'dark-outline-primary': 'dark-outline-primary',
  primary: 'primary',
  'primary-flat': 'primary-flat',
  'primary-outline': 'primary-outline',
  'primary-shadow': 'primary-shadow',
  'dark-shadow': 'dark-shadow',
  white: 'white',
  'danger-outline': 'danger-outline',
  'link-dark': 'link-dark',
  'link-dark-gray': 'link-dark-gray',
  'link-gray-primary': 'link-gray-primary',
  'link-white': 'link-white',
  'link-danger': 'link-danger',
  'link-page-button-primary': 'link-page-button-primary',
  'link-page-button': 'link-page-button',
  'link-page-button-gray-primary': 'link-page-button-gray-primary',
  'gray-success': 'gray-success',
  'dark-flat': 'dark-flat',
};

const ButtonThemes = css`
  .button {
    /* Dark - Button Primary */
    &.dark {
      background: var(--dark-color);
      border: none;

      & * {
        color: var(--white-color);
      }

      &:hover {
        background: var(--grayscale-400);
      }

      &:active {
        background: var(--grayscale-300);
      }

      &:disabled,
      &.disabled {
        pointer-events: none;
        background: var(--grayscale-100);
        cursor: initial;

        & * {
          color: var(--grayscale-200);
        }
      }
    }

    /* Dark - Button Outline */

    &.dark-outline {
      background: none;
      border: 1px solid var(--grayscale-100);

      & * {
        color: var(--dark-color);
      }

      &:hover {
        border: 1px solid var(--grayscale-400);
      }

      &:active {
        background: var(--dark-color);

        & * {
          color: var(--white-color);
        }
      }

      &:disabled,
      &.disabled {
        pointer-events: none;
        background: none;
        border-color: var(--grayscale-100);
        cursor: initial;

        & * {
          color: var(--grayscale-100);
        }
      }
    }

    /* Button Outline Purple */

    &.dark-outline-primary {
      background: none;
      border: 1px solid var(--grayscale-100);

      & * {
        color: var(--primary-color);
      }

      &:hover {
        background: var(--primary-color);
        border-color: var(--primary-color);

        & * {
          color: var(--white-color);
        }
      }

      &:active {
        background: var(--dark-color);

        & * {
          color: var(--white-color);
        }
      }

      &:disabled,
      &.disabled {
        pointer-events: none;
        background: none;
        border-color: var(--grayscale-100);
        cursor: initial;

        & * {
          color: var(--grayscale-100);
        }
      }
    }

    /* Purple - Button Purple */

    &.primary {
      background: var(--primary-color);
      border-color: var(--primary-color);

      & * {
        color: var(--white-color);
      }

      &:hover {
        background: var(--primary-color-300);
        border-color: var(--primary-color-300);
      }

      &:active {
        background: var(--primary-color-300);
        border-color: var(--primary-color-300);
      }

      &:disabled,
      &.disabled {
        pointer-events: none;
        background: var(--grayscale-100);
        cursor: initial;

        & * {
          color: var(--grayscale-200);
        }
      }
    }

    /* Purple - Button Flat */

    &.primary-flat {
      background: var(--gray-color);
      border-color: var(--gray-color);

      & * {
        color: var(--primary-color);
      }

      &:hover {
        background: var(--primary-color);
        border-color: var(--primary-color);

        & * {
          color: var(--white-color);
        }
      }

      &:active {
        background: var(--primary-color-300);
        border-color: var(--primary-color-300);
      }

      &:disabled,
      &.disabled {
        pointer-events: none;
        background: var(--grayscale-100);
        cursor: initial;

        & * {
          color: var(--grayscale-200);
        }
      }
    }

    &.dark-flat {
      background: var(--gray-color);
      border-color: var(--gray-color);

      & * {
        color: var(--dark-color);
      }

      &:hover {
        background: var(--dark-color);
        border-color: var(--dark-color);

        & * {
          color: var(--white-color);
        }
      }

      &:active {
        background: var(--dark-color);
        border-color: var(--dark-color);
      }

      &:disabled,
      &.disabled {
        pointer-events: none;
        background: var(--grayscale-100);
        cursor: initial;

        & * {
          color: var(--grayscale-200);
        }
      }
    }

    &.primary-outline {
      background: none;
      border: 1px solid var(--primary-color-100);

      & * {
        color: var(--primary-color);
      }

      &:hover {
        border-color: var(--primary-color);
      }

      &:active {
        background: var(--primary-color-300);
        border-color: var(--primary-color-300);

        & * {
          color: var(--white-color);
        }
      }

      &:disabled,
      &.disabled {
        pointer-events: none;
        background: none;
        border-color: var(--grayscale-100);
        cursor: initial;

        & * {
          color: var(--grayscale-100);
        }
      }
    }

    &.primary-shadow {
      background: var(--white-color);
      border: 1px solid var(--white-color);
      box-shadow: 0px 0px 25px 4px rgba(0, 0, 0, 0.05);

      & * {
        color: var(--primary-color);
      }

      &:hover {
        background: var(--white-color);
        border-color: var(--primary-color-300) !important;
        box-shadow: none;

        & * {
          color: var(--primary-color-300);
        }
      }

      &:active {
        background: var(--primary-color-300);
        border-color: var(--primary-color-300);

        & * {
          color: var(--white-color);
        }
      }

      &:disabled,
      &.disabled {
        pointer-events: none;
        background: var(--grayscale-100);
        border: none;
        box-shadow: none;
        cursor: initial;

        & * {
          color: var(--grayscale-200);
        }
      }
    }

    &.dark-shadow {
      background: var(--white-color);
      border: 1px solid var(--white-color);
      box-shadow: 0px 0px 25px 4px rgba(0, 0, 0, 0.05);

      & * {
        color: var(--dark-color);
      }

      &:hover {
        background: var(--white-color);
        border-color: var(--dark-color-300) !important;
        box-shadow: none;

        & * {
          color: var(--dark-color-300);
        }
      }

      &:active {
        background: var(--dark-color-300);
        border-color: var(--dark-color-300);

        & * {
          color: var(--white-color);
        }
      }

      &:disabled,
      &.disabled {
        pointer-events: none;
        background: var(--grayscale-100);
        border: none;
        box-shadow: none;
        cursor: initial;

        & * {
          color: var(--grayscale-200);
        }
      }
    }

    &.white {
      background: var(--white-color);
      border: 1px solid var(--grayscale-100);

      & * {
        color: var(--dark-color);
      }

      &:hover {
        background: var(--dark-color);
        border: 1px solid var(--dark-color);

        & * {
          color: var(--white-color);
        }
      }

      &:active {
        background: var(--grayscale-300);
        border-color: var(--grayscale-300);

        & * {
          color: var(--white-color);
        }
      }

      &:disabled,
      &.disabled {
        pointer-events: none;
        background: var(--white-color);
        border-color: var(--grayscale-100);
        cursor: initial;

        & * {
          color: var(--grayscale-100);
        }
      }
    }

    &.danger-outline {
      background: none;
      border: 1px solid var(--primary-color-100);

      & * {
        color: var(--danger-color);
      }

      &:hover {
        border-color: var(--danger-color);
      }

      &:active {
        background: var(--danger-color);
        border-color: var(--danger-color);

        & * {
          color: var(--white-color);
        }
      }

      &:disabled,
      &.disabled {
        pointer-events: none;
        background: none;
        border-color: var(--grayscale-100);
        cursor: initial;

        & * {
          color: var(--grayscale-100);
        }
      }
    }

    /* Link themes */

    &.link-dark {
      background: none;
      border: none;

      & * {
        color: var(--grayscale-200);
      }

      &:hover {
        & * {
          color: var(--grayscale-400);
        }
      }

      &:active {
        & * {
          color: var(--grayscale-300);
        }
      }

      &:disabled,
      &.disabled {
        pointer-events: none;
        cursor: initial;

        & * {
          color: var(--grayscale-100);
        }
      }
    }

    &.link-dark-gray {
      background: none;
      border: none;

      & * {
        color: var(--grayscale-200);
      }

      &:hover {
        background: var(--gray-color);

        & * {
          color: var(--grayscale-400);
        }
      }

      &:active {
        background: var(--grayscale-100);

        & * {
          color: var(--grayscale-400);
        }
      }

      &:disabled,
      &.disabled {
        pointer-events: none;
        cursor: initial;

        & * {
          color: var(--grayscale-100);
        }
      }
    }

    &.link-primary {
      background: none;
      border: none;

      & * {
        color: var(--primary-color);
      }

      &:hover {
        & * {
          color: var(--primary-color-300);
        }
      }

      &:active {
        & * {
          color: var(--primary-color-300);
        }
      }

      &:disabled,
      &.disabled {
        pointer-events: none;
        cursor: initial;

        & * {
          color: var(--grayscale-100);
        }
      }
    }

    &.link-gray-primary {
      background: none;
      border: none;

      & * {
        color: var(--grayscale-200);
      }

      &:hover {
        & * {
          color: var(--primary-color);
        }
      }

      &:active {
        & * {
          color: var(--primary-color-300);
        }
      }

      &:disabled,
      &.disabled {
        pointer-events: none;
        cursor: initial;

        & * {
          color: var(--grayscale-100);
        }
      }
    }

    &.link-white {
      background: none;
      border: none;

      & * {
        color: var(--white-color);
      }

      &:hover {
        & * {
          color: var(--primary-color-100);
        }
      }

      &:active {
        & * {
          color: var(--grayscale-200);
        }
      }

      &:disabled,
      &.disabled {
        pointer-events: none;
        cursor: initial;

        & * {
          color: var(--grayscale-100);
        }
      }
    }

    &.link-danger {
      background: none;
      border: none;

      & * {
        color: var(--danger-color);
      }

      &:hover {
        & * {
          color: var(--danger-color-300);
        }
      }

      &:active {
        & * {
          color: var(--danger-color-300);
        }
      }

      &:disabled,
      &.disabled {
        pointer-events: none;
        cursor: initial;

        & * {
          color: var(--grayscale-100);
        }
      }
    }

    &.link-page-button-primary {
      background: none;
      border: none;

      & * {
        color: var(--primary-color);
      }

      &:hover {
        background: var(--gray-color);
      }

      &:active {
        background: var(--primary-color);
        & * {
          color: var(--white-color);
        }
      }

      &:disabled,
      &.disabled {
        pointer-events: none;
        cursor: initial;

        & * {
          color: var(--grayscale-100);
        }
      }
    }

    &.link-page-button {
      background: none;
      border: none;

      & * {
        color: var(--dark-color);
      }

      &:hover {
        background: var(--gray-color);
      }

      &:active {
        background: var(--dark-color);
        & * {
          color: var(--white-color);
        }
      }

      &:disabled,
      &.disabled {
        pointer-events: none;
        cursor: initial;

        & * {
          color: var(--grayscale-100);
        }
      }
    }

    &.link-page-button-gray-primary {
      background: none;
      border: none;

      & * {
        color: var(--grayscale-200);
      }

      &:hover {
        background: var(--gray-color);
      }

      &:active {
        background: var(--gray-color);
        & * {
          color: var(--primary-color);
        }
      }

      &:disabled,
      &.disabled {
        pointer-events: none;
        cursor: initial;

        & * {
          color: var(--grayscale-100);
        }
      }
    }

    &.gray-success {
      background: var(--gray-color);
      border-color: var(--gray-color);

      & * {
        color: var(--grayscale-200);
      }

      &:hover {
        background: var(--success-color-100);
        border-color: var(--success-color-100);

        & * {
          color: var(--grayscale-200);
        }
      }

      &:active {
        background: var(--success-color);
        border-color: var(--success-color);

        & * {
          color: var(--white-color);
        }
      }

      &:disabled,
      &.disabled {
        pointer-events: none;
        background: var(--grayscale-100);
        cursor: initial;

        & * {
          color: var(--grayscale-200);
        }
      }
    }
  }
`;

export default ButtonThemes;
