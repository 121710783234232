import styled from 'styled-components';

interface StyledImageProps {
  radius: string;
  width: string;
  height: string;
}

export const StyledImage = styled.img<StyledImageProps>`
  border-radius: ${({ radius }) => radius};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`;

export const StyledPlaceholderImage = styled.div<StyledImageProps>`
  width: ${({ width }) => (width === 'auto' ? '70px' : width)};
  height: ${({ height }) => (height === 'auto' ? '70px' : height)};
  border-radius: ${({ radius }) => radius};
  background-color: var(--grayscale-100);
  position: relative;

  .icon {
    position: absolute;
    color: var(--grayscale-300);
    top: calc(50% - 11px);
    left: calc(50% - 11px);
    font-size: 22px;
    opacity: 1;
  }
`;
