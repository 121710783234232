import styled from 'styled-components';
import { Link } from 'react-router-dom';

const getButtonSizeStyle = (size: string) => {
  if (size === 'big') {
    return `
      padding: 14px 12px;
      height: 52px;
      min-width: 52px;
    `;
  }

  if (size === 'small') {
    return `
      padding: 8px;
      height: 40px;
      min-width: 40px;

      i#button-left-icon {
        display: none;
      }

      i#button-right-icon {
        display: none;
      }
    `;
  }

  return `
    padding: 10px 12px;
    height: 44px;
    min-width: 44px;
  `;
};

interface StyledButtonProps {
  theme: string;
  rounded?: string;
  radius?: string;
  size: string;
  disabled?: boolean | undefined;
}

export const StyledButton = styled.button<StyledButtonProps>`
  ${(props) => getButtonSizeStyle(props.size)}
  width: max-content;
  border-radius: ${(props) =>
    props.radius || (props.rounded === 'true' ? '100px' : '8px')};
  box-sizing: border-box;
  cursor: pointer;
  border: none;
  outline: none;
  position: relative;
  transition: background 0.2s, border 0.2s, box-shadow 0.2s;
  -webkit-transition: background 0.2s, border 0.2s, box-shadow 0.2s;
  -moz-transition: background 0.2s, border 0.2s, box-shadow 0.2s;
  -o-transition: background 0.2s, border 0.2s, box-shadow 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;

  & > div {
    line-height: 1;
    margin-top: 2px;
    font-size: 16px;
    font-weight: 500;
  }

  i#button-left-icon {
    padding-left: 4px;
    padding-right: 12px;
  }

  i#button-right-icon {
    padding-left: 12px;
    padding-right: 4px;
  }

  .counter-label {
    position: absolute;
    top: -12px;
    right: -12px;
  }
`;

export const StyledAnchor = styled.a<StyledButtonProps>`
  ${(props) => getButtonSizeStyle(props.size)}
  width: max-content;
  border-radius: ${(props) =>
    props.radius || (props.rounded === 'true' ? '100px' : '8px')};
  box-sizing: border-box;
  cursor: pointer;
  text-decoration: none;
  border: none;
  outline: none;
  position: relative;
  transition: background 0.2s;
  -webkit-transition: border 0.2s;
  -moz-transition: border 0.2s;
  -o-transition: border 0.2s;
  transition: border 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;

  & > div {
    line-height: 1;
    margin-top: 2px;
    font-size: 16px;
    font-weight: 500;
  }

  i#button-left-icon {
    padding-left: 4px;
    padding-right: 12px;
  }

  i#button-right-icon {
    padding-left: 12px;
    padding-right: 4px;
  }

  .counter-label {
    position: absolute;
    top: -12px;
    right: -12px;
  }
`;

export const StyledLink = styled(Link)<StyledButtonProps>`
  ${(props) => getButtonSizeStyle(props.size)}
  width: max-content;
  border-radius: ${(props) =>
    props.radius || (props.rounded === 'true' ? '100px' : '8px')};
  box-sizing: border-box;
  cursor: pointer;
  border: none;
  outline: none;
  text-decoration: none;
  position: relative;
  transition: background 0.2s;
  -webkit-transition: border 0.2s;
  -moz-transition: border 0.2s;
  -o-transition: border 0.2s;
  transition: border 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;

  & > div {
    line-height: 1;
    margin-top: 2px;
    font-size: 16px;
    font-weight: 500;
  }

  i#button-left-icon {
    padding-left: 4px;
    padding-right: 12px;
  }

  i#button-right-icon {
    padding-left: 12px;
    padding-right: 4px;
  }

  .counter-label {
    position: absolute;
    top: -12px;
    right: -12px;
  }
`;
