import React from 'react';
import { useTranslation } from 'react-i18next';

import { GridContainer, GridRow, GridColumn } from 'components/atom/Grid';
import Text from 'components/atom/Text';
import Button from 'components/molecule/Button';
import Image from 'components/molecule/Image';

import pageNotFoundGif from 'assets/images/pagenotfound.gif';

import { StyledError404 } from './styles';

const Error404: React.FC = () => {
  const { t } = useTranslation();

  return (
    <StyledError404 className="error-404">
      <GridContainer>
        <GridRow>
          <GridColumn desktop="6" tablet="6" mobile="12">
            <div className="message-wrapper">
              <Text as="h1">{t('404')}</Text>
              <Text as="h2">{t('Page not found')}</Text>
              <Text as="h5">
                {t(
                  'The page you tried to access is not available. Check the URL and try again.',
                )}
              </Text>
              <div className="button-wrapper">
                <Button to="/">{t('Back to home')}</Button>
              </div>
            </div>
          </GridColumn>
          <GridColumn desktop="6" tablet="6" mobile="12">
            <div className="image-wrapper">
              <Image src={pageNotFoundGif} />
            </div>
          </GridColumn>
        </GridRow>
      </GridContainer>
    </StyledError404>
  );
};

export default Error404;
