import { configureStore } from '@reduxjs/toolkit';

import appLoading from './slices/appLoading';
import theme from './slices/theme';
import toaster from './slices/toaster';
import pageLoading from './slices/pageLoading';
import modal from './slices/modal';
import client from './slices/client';
import user from './slices/user';
import organization from './slices/organization';
import organizations from './slices/organizations';

const store = configureStore({
  reducer: {
    appLoading,
    organization,
    theme,
    toaster,
    pageLoading,
    modal,
    client,
    user,
    organizations,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
