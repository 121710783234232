import Card from 'components/atom/Card';
import React, { useEffect, useState } from 'react';

import Text from 'components/atom/Text';

import { StyledForm, StyledFormHeight100 } from '../FormStyles/styles';
import PageLoading from 'components/molecule/PageLoading';
import { getStaffDashboard } from 'apis/forms/api';
import { getAccessToken, logout } from 'services/auth';
import { useNavigate } from 'react-router-dom';

// import { getFormStats } from 'apis/forms/api';

const Dashboard: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [dashboards, setDashboards] = useState(['']);
  const navigate = useNavigate();

  useEffect(() => {
    const accessToken = getAccessToken();
    if (!accessToken) {
      navigate('/login');
    } else {
      getStaffDashboard()
        .then((response) => {
          setDashboards(response.data.dashboards);
        })
        .catch((err) => {
          if (err.response.status === 401) {
            logout();
            navigate('/login');
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  let content = (
    <StyledFormHeight100>
      <PageLoading />
    </StyledFormHeight100>
  );

  if (!loading) {
    if (dashboards[0] === '' && dashboards.length === 1) {
      content = (
        <StyledFormHeight100>
          <Card noBorder="true">
            <Text as="h4" align="center" weight="600">
              Erro ao devolver estatísticas!
            </Text>
          </Card>
        </StyledFormHeight100>
      );
    } else {
      const dashboards_content = dashboards.map((dashboard, i) => (
        <Card key={i} noBorder="true">
          <div>
            <iframe
              src={dashboard}
              loading="lazy"
              width="100%"
              height="700px"
              title="Dashboard"
            ></iframe>
          </div>
        </Card>
      ));

      content = (
        <StyledForm>
          <div className="wrap-container">
            <div className="text-container">
              <Text as="h3" align="left" weight="700" color="grayscale-400">
                Estatísticas Gerais dos Questionários
              </Text>
              <Text as="h5" align="left" weight="600" color="grayscale-300">
                Visão de Staff
              </Text>
            </div>
            {dashboards_content}
          </div>
        </StyledForm>
      );
    }
  }

  return content;
};

export default Dashboard;
