import { appName } from 'settings';

export const ACCESS_TOKEN_KEY = `@${appName}/access_token`;

export const REFRESH_TOKEN_KEY = `@${appName}/refresh_token`;

export const CLIENT_SLUG_KEY = `@${appName}/client_slug`;

export const I18NEXT_LNG = 'i18nextLng';

export const CURRENT_ORGANIZATION_ID_KEY = `@${appName}/current_organization_id`;
