import Card from 'components/atom/Card';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Text from 'components/atom/Text';

import { StyledForm, StyledFormHeight100 } from '../FormStyles/styles';
import PageLoading from 'components/molecule/PageLoading';

import { getFormStats } from 'apis/forms/api';

const FormStats: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    status: false,
    company: '',
    start_at: '',
    finish_at: '',
    dashboards: [''],
  });

  const { token } = useParams();
  useEffect(() => {
    //check form validity
    getFormStats(token as string)
      .then((response) => {
        const data = response.data;
        setFormData(() => {
          return {
            status: true,
            ...data,
          };
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [token]);

  const dateConverted = (dateReceived: string) => {
    const formatTwoDigits = (digit: number) => ('0' + digit).slice(-2);
    const date = new Date(dateReceived);
    return `${formatTwoDigits(date.getDate())}/${formatTwoDigits(
      date.getMonth() + 1,
    )}/${date.getFullYear()} às ${formatTwoDigits(
      date.getHours(),
    )}:${formatTwoDigits(date.getMinutes())}h`;
  };

  let content = (
    <StyledFormHeight100>
      <PageLoading />
    </StyledFormHeight100>
  );

  if (!loading) {
    if (formData.status === false) {
      content = (
        <StyledFormHeight100>
          <Card noBorder="true">
            <Text as="h4" align="center" weight="600">
              Questionário inexistente!
            </Text>
            <Text as="p" align="center" color="grayscale-300">
              O questionário que está a tentar visualizar não existe!
            </Text>
          </Card>
        </StyledFormHeight100>
      );
    } else {
      const dashboards = formData.dashboards.map((dashboard, i) => (
        <Card key={i} noBorder="true">
          <div>
            <iframe
              src={dashboard}
              loading="lazy"
              width="100%"
              height="600px"
              title="Inquérito de Felicidade no Trabalho"
            ></iframe>
          </div>
        </Card>
      ));

      content = (
        <StyledForm>
          <div className="wrap-container">
            <div className="text-container">
              <Text as="h3" align="left" weight="700" color="grayscale-400">
                {formData.company} - Estatísticas
              </Text>
              <Text as="h5" align="left" weight="600" color="grayscale-300">
                De {dateConverted(formData.start_at)} até{' '}
                {dateConverted(formData.finish_at)}
              </Text>
            </div>
            {dashboards}
          </div>
        </StyledForm>
      );
    }
  }

  return content;
};

export default FormStats;
