import styled from 'styled-components';

export const StyledFastMenu = styled.div.attrs(() => ({
  className: 'fast-menu',
}))`
  height: 100%;
  background: var(--primary-color);
  padding: 44px 10px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .button {
    margin-bottom: 10px;
    width: 52px;
    height: 52px;
    transition: background 0.2s;
    i {
      font-size: 24px;
    }
  }

  .top-options {
    .button {
      background: var(--primary-color-300);
      i {
        color: var(--primary-color-100);
      }
      &:hover {
        background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.2),
            rgba(0, 0, 0, 0.2)
          ),
          var(--primary-color-300);
      }
    }
  }

  .bottom-options {
    .button {
      background: var(--primary-color-100);
      i {
        color: var(--primary-color-300);
      }
      &:hover {
        background: var(--grayscale-100);
      }
    }
  }
`;
