import axios, { AxiosRequestConfig } from 'axios';

import { ApiVersions, getBaseAPIUrl } from 'settings';

import { getAccessToken } from 'services/auth';
import { getI18NextLanguage } from 'services/language';

const instance = axios.create({
  baseURL: getBaseAPIUrl(ApiVersions.current),
});

instance.interceptors.request.use(async (config: AxiosRequestConfig) => {
  const accessToken = getAccessToken();

  if (!accessToken || !config.headers) {
    return config;
  }

  config.headers.Authorization = `USEFLOW ${accessToken}`;
  config.headers['Accept-Language'] = getI18NextLanguage();

  return config;
});

export default instance;
