import { environmentBaseUrlsTypes } from 'types/settingTypes';

const {
  REACT_APP_APP_NAME,
  REACT_APP_CURRENT_ENVIRONMENT,
  REACT_APP_LOCAL_BASE_URL,
  REACT_APP_PROD_BASE_URL,
  REACT_APP_HOMO_BASE_URL,
  REACT_APP_VERSION,
  REACT_APP_DEFAULT_CLIENT_NAME,
} = process.env;

const environmentBaseUrls: environmentBaseUrlsTypes = {
  local: REACT_APP_LOCAL_BASE_URL,
  prod: REACT_APP_PROD_BASE_URL,
  homo: REACT_APP_HOMO_BASE_URL,
};

export enum ApiVersions {
  v1 = 'v1',
  v2 = 'v2',
  current = v2,
}

export const appName = REACT_APP_APP_NAME;

export const appVersion = REACT_APP_VERSION;

export const environment = REACT_APP_CURRENT_ENVIRONMENT;

export const defaultClientName = REACT_APP_DEFAULT_CLIENT_NAME || 'USEFLOW';

export const getBaseAPIUrl = (version: ApiVersions) => {
  const env = REACT_APP_CURRENT_ENVIRONMENT as keyof environmentBaseUrlsTypes;
  const url = environmentBaseUrls[env];

  return `${url}${version}`;
};

export const getDirectLegacyAdminURL = (url: string) => {
  // URL without redirection! If you need with it, look for `RedirectToLegacy`
  const env = REACT_APP_CURRENT_ENVIRONMENT as keyof environmentBaseUrlsTypes;
  const baseUrl = environmentBaseUrls[env];

  return new URL(url, baseUrl).toString();
};

export const CRISP_ID = '6db8e69f-140e-4fa5-89dd-43f267ee3f3f';

export const BASE_UPLOAD_URL = `${getBaseAPIUrl(ApiVersions.v2)}/admin`;
export const BASE_SSO_URL = 'https://ms.auth.useflow.tech/api/v1/sso';

export const SSO_APP_ID = '31fcdfc7-ec73-45f9-8fdd-488b05616542';
export const SSO_APP_KEY = '6e7187e29befb84ee186abc470b5689e9c7aea26';

export const GOOGLE_MAPS_API_KEY = 'AIzaSyCr6z4PgD9n0RDGLSrn7TMVmSwWMJMmTA8';
