import { UseflowIcons } from 'assets/fonts/UseflowIcons/types';
import { AvailableColorTypes } from 'styles/global-styles';

export const AvailableIcons = UseflowIcons;

export interface IconProps {
  name: keyof typeof AvailableIcons;
  color?: keyof typeof AvailableColorTypes;
  id?: string;
}

export interface StyledIconProps {
  name: keyof typeof AvailableIcons;
  color?: keyof typeof AvailableColorTypes;
}
