import styled from 'styled-components';

export const StyledError404 = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  .grid-container {
    height: 100%;
    max-width: 1000px;
    padding: 32px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .message-wrapper {
    max-width: 335px;
    position: relative;
    z-index: --z-index-level-2;
    margin-bottom: 64px;

    h1 {
      font-size: 200px;
      margin-bottom: 32px;
    }

    h2 {
      max-width: 300px;
      margin-bottom: 10px;
    }

    h5 {
      max-width: 335px;
      font-weight: 500;
    }

    .button-wrapper {
      margin-top: 42px;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  .image-wrapper {
    height: 420px;
    display: flex;
    justify-content: center;
    align-items: center;

    .image {
      width: 100%;
      max-width: 500px;
    }
  }

  @media (max-width: 720px) {
    .grid-container {
      justify-content: flex-start;
    }
  }
`;
