import { createSlice } from '@reduxjs/toolkit';

import PageLoadingState from './types';

const initialState: PageLoadingState = {
  show: false,
};

export const PageLoadingSlice = createSlice({
  name: 'pageLoading',
  initialState,
  reducers: {
    setPageLoading: (state, action) => {
      state.show = action.payload;
    },
  },
});

export const { setPageLoading } = PageLoadingSlice.actions;

export default PageLoadingSlice.reducer;
