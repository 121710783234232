import React from 'react';
import { useTranslation } from 'react-i18next';

import FastMenu from 'components/organism/FastMenu';
import Header from 'components/atom/Header';
import Logo from 'components/molecule/Logo';

import { StyledFormsDashboardLayout } from './styles';
import { FastMenuOptionProp } from 'components/organism/FastMenu/types';
import Button from 'components/molecule/Button';

import { logout } from 'services/auth';
import { useNavigate } from 'react-router-dom';

const FormsDashboardLayout: React.FC<
  React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({ children }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleLogoutClick = () => {
    logout();
    navigate('/login');
  };

  // Fast menu options
  const topOptions: FastMenuOptionProp[] = [];

  const bottomOptions: FastMenuOptionProp[] = [];

  const logoutButton = (
    <Button theme="primary" onClick={handleLogoutClick}>
      Logout
    </Button>
  );

  return (
    <StyledFormsDashboardLayout>
      <div className="menus-wrapper">
        <FastMenu topOptions={topOptions} bottomOptions={bottomOptions} />
      </div>
      <div className="content default-scroll">
        <Header leftSideContent={<Logo />} rightSideContent={logoutButton} />
        {children}
      </div>
    </StyledFormsDashboardLayout>
  );
};

export default FormsDashboardLayout;
