import publicApi from 'apis/publicApi';
import apiV2 from 'apis/apiV2';

import { SignInProps } from './types';

const basePathname = '/auth';

export const getClientConfig = (clientSlug: string) =>
  publicApi.get(`${basePathname}/client/${clientSlug}`);

export const signIn = (data: SignInProps) =>
  publicApi.post(`${basePathname}/login/`, {
    client: data.client,
    username: data.username,
    password: data.password,
  });

export const refreshToken = (token: string) =>
  publicApi.post(`${basePathname}/refresh/`, {
    refresh: token,
  });

export const getAccount = () => apiV2.get(`${basePathname}/me/`);

export const getLegacySession = (pageUrl: string, organizationId: string) =>
  apiV2.get(
    `${basePathname}/session/?next=${pageUrl}?organization=${organizationId}`,
  );

export const loginSSO = (identityId: string) => apiV2.get(`sso/${identityId}`);
