import React from 'react';

import { StyledIcon } from './styles';
import { IconProps } from './types';

const Icon: React.FC<IconProps> = ({ id, name, color = 'dark-color' }) => {
  return <StyledIcon name={name} color={color} id={id} />;
};

export default Icon;
