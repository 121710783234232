import styled from 'styled-components';

export const StyledCounterLabel = styled.div.attrs(() => ({
  className: 'counter-label',
}))`
  width: max-content;
  min-width: 24px;
  height: 24px;
  padding: 0px 4px;
  border-radius: 100px;
  color: var(--white-color) !important;
  background: var(--primary-color);
  line-height: 112%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
