import React from 'react';

import Icon from '../../atom/Icon';

import { StyledImage, StyledPlaceholderImage } from './styles';
import { ImageProps } from './types';

const Image: React.FC<ImageProps> = ({
  src,
  width = 'auto',
  height = 'auto',
  radius = '4px',
  className = '',
  placeholderIcon = 'image-fill',
}) => {
  if (src) {
    return (
      <StyledImage
        src={src}
        className={`image ${className}`}
        width={width}
        height={height}
        radius={radius}
      />
    );
  }

  return (
    <StyledPlaceholderImage
      className={`image ${className}`}
      width={width}
      height={height}
      radius={radius}
    >
      <Icon name={placeholderIcon} />
    </StyledPlaceholderImage>
  );
};

export default Image;
