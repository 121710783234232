import React from 'react';

import Icon from 'components/atom/Icon';
import Text from 'components/atom/Text';

import { StyledAlert } from './styles';
import { AvailableIcons } from '../../atom/Icon/types';

interface AlertProps {
  icon?: keyof typeof AvailableIcons;
  title?: string;
  description?: string;
  theme?: string;
  className?: string;
  children?: React.ReactNode;
}

const Alert: React.FC<AlertProps> = ({
  icon = '',
  title = '',
  description = '',
  theme = 'warning',
  className = '',
  children,
}) => {
  return (
    <StyledAlert className={`alert ${className} ${theme}`}>
      <div className="alert-info">
        {icon && <Icon name={icon} />}
        <div className="text-wrapper">
          {title && (
            <Text as="h6" weight="bold" className="title">
              {title}
            </Text>
          )}
          {description && <Text className="description">{description}</Text>}
        </div>
      </div>
      <div className="alert-actions">{children}</div>
    </StyledAlert>
  );
};

export default Alert;
