import { useEffect } from 'react';

import * as dayjs from 'dayjs';
import utcPlugin from 'dayjs/plugin/utc';
import timezonePlugin from 'dayjs/plugin/timezone';
import localizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(utcPlugin);
dayjs.extend(timezonePlugin);
dayjs.extend(localizedFormat);

import { useAppSelector } from 'store/hooks';
import ClientState from 'store/slices/client/types';

import { getI18NextLanguage } from 'services/language';

const useDateFormatterConfig = () => {
  const client: ClientState = useAppSelector((state) => state.client);
  const organizationTimezone: string = useAppSelector(
    (state) => state.organization.timezone,
  );
  const currentLanguage = getI18NextLanguage();

  const setDateLocale = (language: string, timezone: string) => {
    if (!(language && timezone)) return;

    // Even if module is already loaded, instance will be reused
    // Fortunately, JS-TS interpreter is smart enough to do it
    import(`dayjs/locale/${language.toLowerCase()}.js`)
      .then(() => {
        dayjs.locale(language);
        dayjs.tz.setDefault(timezone);
      })
      .catch(() => {
        // Fallback to en-us, it is default from dayjs
        dayjs.locale('en-us');
        dayjs.tz.setDefault('en-us');
      });
  };

  useEffect(() => {
    const { language, timezone } = client;
    setDateLocale(
      currentLanguage || language,
      organizationTimezone || timezone,
    );
  }, [client, organizationTimezone, currentLanguage]);

  return null;
};

export default useDateFormatterConfig;
