import React from 'react';

import Icon from 'components/atom/Icon';
import CounterLabel from 'components/atom/CounterLabel';

import { StyledAnchor, StyledButton, StyledLink } from './styles';
import { AvailableIcons } from '../../atom/Icon/types';
import {
  AvailableSizeTypes,
  AvailableThemeTypes,
} from '../../../styles/button-themes';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  theme?: keyof typeof AvailableThemeTypes;
  rounded?: string;
  radius?: string;
  size?: keyof typeof AvailableSizeTypes;
  badge?: string;
  leftIcon?: keyof typeof AvailableIcons;
  rightIcon?: keyof typeof AvailableIcons;
  href?: string;
  to?: string;
  disabled?: boolean;
  className?: string;
}

const Button: React.FC<ButtonProps> = ({
  theme = 'dark',
  rounded = 'false',
  radius = undefined,
  size = 'normal',
  badge = null,
  leftIcon = '',
  rightIcon = '',
  disabled = false,
  onClick,
  href = null,
  to = null,
  className = '',
  type = 'button',
  children,
}) => {
  if (href) {
    return (
      <StyledAnchor
        theme={theme}
        rounded={rounded}
        radius={radius}
        disabled={disabled ? true : undefined}
        size={size}
        href={href}
        className={`button ${className} ${disabled ? 'disabled' : ''} ${theme}`}
      >
        <Icon name={leftIcon} id="button-left-icon" />
        <div className="button-content">{children}</div>
        <Icon name={rightIcon} id="button-right-icon" />
        {badge && <CounterLabel value={badge} />}
      </StyledAnchor>
    );
  }

  if (to) {
    return (
      <StyledLink
        theme={theme}
        rounded={rounded}
        radius={radius}
        disabled={disabled ? true : undefined}
        size={size}
        to={to}
        className={`button ${className} ${disabled ? 'disabled' : ''} ${theme}`}
      >
        <Icon name={leftIcon} id="button-left-icon" />
        <div className="button-content">{children}</div>
        <Icon name={rightIcon} id="button-right-icon" />
        {badge && <CounterLabel value={badge} />}
      </StyledLink>
    );
  }

  return (
    <StyledButton
      type={type}
      theme={theme}
      rounded={rounded}
      radius={radius}
      size={size}
      disabled={disabled ? true : undefined}
      onClick={onClick}
      className={`button ${className} ${disabled ? 'disabled' : ''} ${theme}`}
    >
      <Icon name={leftIcon} id="button-left-icon" />
      <div className="button-content">{children}</div>
      <Icon name={rightIcon} id="button-right-icon" />
      {badge && <CounterLabel value={badge} />}
    </StyledButton>
  );
};

export default Button;
