import styled from 'styled-components';

interface StyledCardProps {
  color: string;
  shadow: string;
  noBorder: string;
}

export const StyledCard = styled.div<StyledCardProps>`
  width: 100%;
  box-shadow: ${({ shadow }) =>
    shadow === 'true' ? '0px 0px 25px 4px rgba(0, 0, 0, 0.05)' : 'none'};
  border-radius: 8px;
  background: var(--${({ color }) => color});
  border: ${({ shadow, noBorder }) =>
    shadow !== 'true' && noBorder !== 'true'
      ? '1px solid var(--grayscale-100)'
      : 'none'};
`;
