import styled from 'styled-components';

export const StyledFormLayout = styled.div.attrs(() => ({
  className: 'form-layout',
}))`
  width: 100%;
  height: 100%;

  display: flex;

  .menus-wrapper {
    display: flex;
  }

  .content {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
  }
`;

export default StyledFormLayout;
