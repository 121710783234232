import React from 'react';

import Image from 'components/molecule/Image';
import Loading from 'components/molecule/Loading';

import appLogo from 'assets/images/app-logo.svg';
import appLogoInverse from 'assets/images/app-logo-inverse.svg';

import { StyledAppLoading } from './styles';

interface AppLoadingProps {
  removeAnimation?: boolean;
}

const AppLoading: React.FC<AppLoadingProps> = ({ removeAnimation = false }) => {
  return (
    <StyledAppLoading
      className={`app-loading ${!removeAnimation ? 'show-animation' : ''}`}
    >
      <Image src={appLogo} className="app-logo" />
      <Image src={appLogoInverse} className="app-logo-inverse" />
      <Loading type="bubbles" color="white-color" />
    </StyledAppLoading>
  );
};

export default AppLoading;
