import React from 'react';

import { useAppDispatch } from 'store/hooks';

import { setModalView } from 'store/slices/modal';

import Button from 'components/molecule/Button';
import Icon from 'components/atom/Icon';
import Card from 'components/atom/Card';

import { StyledModal } from './styles';

interface ModalProps {
  width?: string;
  content?: React.ReactNode;
  disableBackgroundClick?: boolean;
  onAfterClose?: () => void;
  hideCloseButton?: boolean;
}

const Modal: React.FC<ModalProps> = ({
  width = '600px',
  content,
  disableBackgroundClick = false,
  onAfterClose,
  hideCloseButton,
}) => {
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(setModalView({ show: false }));
    if (onAfterClose) {
      onAfterClose();
    }
  };

  const clickAwayListener = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent> & {
      target: HTMLButtonElement;
    },
  ) => {
    if (event.target.classList.contains('modal')) {
      handleClose();
    }
  };

  return (
    <StyledModal
      className="modal default-scroll"
      onClick={!disableBackgroundClick ? clickAwayListener : () => undefined}
      width={width}
    >
      <div className="modal-content-wrapper">
        {!hideCloseButton && (
          <Button
            theme="link-gray-primary"
            rounded="true"
            size="small"
            onClick={handleClose}
            className="close-modal-button"
          >
            <Icon name="close" />
          </Button>
        )}
        <Card
          color="white-color"
          noBorder="true"
          className="modal-content"
          shadow="true"
        >
          {content}
        </Card>
      </div>
    </StyledModal>
  );
};

export default Modal;
